import * as React from 'react';
import * as Action from '../../store/actions';
import { AppState, Project, ProjectsMap } from "../../types/types"
import { connect } from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import { Message } from 'semantic-ui-react';
import LoginForm from "../../forms/LoginForm";
import ProjectDetailView from "./components/ProjectDetailView";
import ProjectList from "./components/ProjectList";
import I18n from "../../i18n/i18n";
import {withTranslation} from "react-i18next";


type StateProps = {
    isAuthenticated: boolean,
    token: string | null,
    locale: string,
    currentProject: Project | null,
    projects: ProjectsMap
};

type Props = {
    isSmallScreen: boolean
};

type DispatchProps = {
    projectSelect: typeof Action.projectSelect,
    projectUpdate: typeof Action.projectUpdate,
    projectCreate: typeof Action.projectCreate,
    projectRemove: typeof Action.projectRemove,
    projectDeleteConfiguration: typeof Action.projectDeleteConfiguration,
    loadProjects: typeof Action.loadProjects
} & Props;

class ProjectController extends React.Component<StateProps & DispatchProps> {

    public render() {

        if (!this.props.isAuthenticated) {
            return (
                <div>
                    <Message
                        info={true}
                        icon="info"
                        header={I18n.t('configurator.component.productView.notAuthenticated.header')}
                        content={I18n.t('configurator.component.productView.notAuthenticated.content')}
                    />
                    <LoginForm />
                </div>
            );
        }

        if (this.props.currentProject !== null) {
            return (
                <ProjectDetailView
                    locale={this.props.locale}
                    token={this.props.token}
                    project={this.props.currentProject}
                    projectSelect={this.props.projectSelect}
                    projectUpdate={this.props.projectUpdate}
                    projectRemove={this.props.projectRemove}
                    projectDeleteConfiguration={this.props.projectDeleteConfiguration}
                    isSmallScreen={this.props.isSmallScreen}
                />
            );
        }

        return (
            <ProjectList
                locale={this.props.locale}
                projects={Object.values(this.props.projects)}
                project={this.props.currentProject}
                token={this.props.token}
                loadProjects={this.props.loadProjects}
                projectSelect={this.props.projectSelect}
                projectRemove={this.props.projectRemove}
                projectCreate={this.props.projectCreate}
            />
        );
    }
}

function mapStateToProps(state: AppState): StateProps {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        token: state.auth.token,
        locale: state.language.language,
        currentProject: state.project.currentProject,
        projects: state.project.projects
    };
}

function mapDispatchToProps(dispatch: Dispatch<Action.SelectProjectAction>): DispatchProps {
    return bindActionCreators(
        {
            projectUpdate: Action.projectUpdate,
            projectSelect: Action.projectSelect,
            projectCreate: Action.projectCreate,
            projectRemove: Action.projectRemove,
            projectDeleteConfiguration: Action.projectDeleteConfiguration,
            loadProjects: Action.loadProjects
        },
        dispatch
    ) as {
        projectSelect: typeof Action.projectSelect;
        projectUpdate: typeof Action.projectUpdate;
        projectCreate: typeof Action.projectCreate;
        projectRemove: typeof Action.projectRemove;
        projectDeleteConfiguration: typeof Action.projectDeleteConfiguration;
        loadProjects: typeof Action.loadProjects
    } & Props;
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ProjectController));