import * as React from 'react';
import { Header } from 'semantic-ui-react';

type Props = {
    rooftopArticle: string,
    extrasArticles: string,
    transitionArticle: string,
    isSmallScreen: boolean
};

class ProductDetailDescriptions extends React.PureComponent<Props> {
    public render() {
        const { rooftopArticle, extrasArticles, transitionArticle, isSmallScreen } = this.props;
        const screenDependingSize = isSmallScreen ? 'small' : 'medium';
        const screenDependingStyle = {margin: isSmallScreen ? '0 0 2px 0' : '0 0 5px 0'}

        return (
            <div className="product-detail-header" style={{position: 'relative'}}>
                <div style={{position: 'absolute', left: '0px', top: '0px'}}>
                    <Header
                        size={screenDependingSize}
                        color="blue"
                        style={screenDependingStyle}
                        content={rooftopArticle}
                    />
                    {
                        extrasArticles.length
                            ? <Header
                                size={screenDependingSize}
                                color="blue"
                                style={screenDependingStyle}
                                content={extrasArticles}
                            />
                            : ''
                    }
                    <Header
                        size={screenDependingSize}
                        color="blue"
                        style={screenDependingStyle}
                        content={transitionArticle}
                    />
                </div>
            </div>
        );
    }
}

export default ProductDetailDescriptions;