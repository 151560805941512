import {
    ConfigurationProduct,
    ConfigurationProducts,
    DataSet, instanceOfRooftopProduct, instanceOfTransitionProduct,
    Option,
    Options,
    Physics,
    Prices,
    Product,
    ProductState,
    RooftopProduct,
    SelectedExtras,
    TransitionProduct,
    UserSelection
} from '../types/types'
import {
    AIRTYPE,
    CONFIGURATION_STATE,
    EXCEPTIONAL_ROOFTOPS,
    NONINSULATED,
    PRODUCT_STATE,
    SYSTEM_ROUND
} from './constants';
import Namer from './Namer';
import getStandardLouverCnt from './getStandardLouverCnt';

export default class ProductStateBuilder {
    private static emptyConfiguration = null;

    private dataSet: DataSet;

    public constructor(dataSet: DataSet) {
        this.dataSet = dataSet;
    }

    public build(newUserSelection: UserSelection, oldUserSelection: UserSelection): ProductState {

        let possibleRooftops = this.getPossibleRooftops(newUserSelection);
        newUserSelection.rooftop = this.replaceRooftopIfNecessary(possibleRooftops, newUserSelection.rooftop);

        let possiblePurposes = this.getPossiblePurposes(newUserSelection);
        newUserSelection.purpose = this.replacePurposeIfNecessary(possiblePurposes, newUserSelection.purpose);

        let possibleTransitions = this.getPossibleTransitions(newUserSelection);

        newUserSelection.model = this.replaceModelIfNessessary(possibleTransitions, newUserSelection.model);
        newUserSelection.assembly = this.replaceAssemblyIfNecessary(possibleTransitions, newUserSelection.assembly);

        const filteredAirTypesForCalc = this.filterAirTypesForCalc(newUserSelection, this.dataSet.options.airTypes)

        newUserSelection.airTypeForCalculations = this.replaceAirTypeForCalcIfNecessary(
            newUserSelection,
            oldUserSelection,
            filteredAirTypesForCalc
        );

        let newPossibleExtras = this.getPossibleExtras(newUserSelection);
        let oldPossibleExtras = this.getPossibleExtras(oldUserSelection);
        newUserSelection.extras = this.replaceExtrasIfNecessary(
            oldUserSelection,
            newUserSelection,
            newPossibleExtras,
            oldPossibleExtras,
            newUserSelection.extras
        );

        newUserSelection.louverSelection = this.replaceLouverSelectionIfNecessary(
            newUserSelection,
            oldUserSelection
        );
        newUserSelection.louverSelIntModified = this.resetLouverSelIntModifiedIfNecessary(
            newUserSelection,
            oldUserSelection
        );

        newUserSelection.roofAngleValueWarning = this.resetRoofAngleValueWarningIfNecessary(newUserSelection);

        let d2 = this.replaceD2IfNecessary(newUserSelection);
        newUserSelection.d2 = d2;

        // D2 for transition might differ from the rooftop
        newUserSelection.insulationMm = 0;
        let d2Transition = null;
        let transition = this.selectTransition(newUserSelection);
        if (transition) {
            d2Transition = this.getD2ForTransition(transition, newUserSelection);
            newUserSelection.insulationMm = transition.insulationMm;
        }
        if (!d2Transition) {
            d2Transition = d2;
        }
        newUserSelection.d2Transition = d2Transition;

        const configuration = this.createConfiguration(newUserSelection);
        //console.log(configuration);

        const filteredDn = this.filterDn(newUserSelection, configuration ? configuration.transition : null);

        newUserSelection.isUserSelectionValid = newUserSelection.system === 2 ||
            filteredDn.includes(newUserSelection.diameter);

        let filteredD2 = this.filterD2(
                newUserSelection,
                configuration && configuration.transition && configuration.transition.product
                    ? configuration.transition.product.prices
                    : null
        );

        return {
            dataSet: this.dataSet,
            filteredOptions: {
                airTypes: this.filterAirTypes(this.dataSet.options.airTypes),
                airTypesForCalculation: filteredAirTypesForCalc,
                insulations: this.filterInsulations(newUserSelection),
                roofAngles: this.dataSet.options.roofAngles,
                roofTypes: this.dataSet.options.roofTypes,
                systems: this.dataSet.options.systems,
                purposes: this.getPossiblePurposes(newUserSelection),
                models: this.filterModels(possibleTransitions),
                assemblys: this.filterAssemblys(possibleTransitions),
                dn: filteredDn,
                d2: filteredD2,
                louvers: this.dataSet.options.louvers,
                extras: newPossibleExtras,
                rooftops: possibleRooftops
            },
            userSelection: newUserSelection,
            configuration: configuration
        };
    }

    private filterInsulations(userSelection: UserSelection): Option[] {
        const validIds = this.availableInsulationIds(userSelection);

        return this.dataSet.options.insulations.filter(item => validIds.includes(item.id));
    }

    // Temporarily filtered because no 'Außenluft' (--> id: 2) roof hoods exist right now --> Nico's wish
    private filterAirTypes(dataSetAirTypes: Option[]): Option[] {
        return dataSetAirTypes.filter(item => item.id !== 2);
    }

    private getPossibleTransitionsForPurposeFilter(userSelection: UserSelection): TransitionProduct[] {
        let rooftop = this.getRooftop(userSelection);

        return this.dataSet.products.transitions.filter(item => {
            return item.system === userSelection.system &&
                item.roofType === userSelection.roofType &&
                item.roofAngle === userSelection.roofAngle &&
                item.insulation === userSelection.insulation &&
                rooftop !== null &&
                (([1, 2].includes(item.assembly) && rooftop.channelProfile) ||
                    ([0, 3, 4, 5].includes(item.assembly) && !rooftop.channelProfile));
        });
    }

    private getPossiblePurposes(userSelection: UserSelection): Option[] {
        const possibleTransitions = this.getPossibleTransitionsForPurposeFilter(userSelection)

        let possiblePurposes: Object = {}
        // @ts-ignore
        possibleTransitions.forEach(transition => possiblePurposes[transition.purpose] = transition.purpose)
        // @ts-ignore
        return this.dataSet.options.purposes.filter(purpose => possiblePurposes[purpose.id] === purpose.id)
    }

    private filterAirTypesForCalc(newUserSelection: UserSelection, airTypes: Option[]): Option[] {
        const selectedRooftop: RooftopProduct | null = this.getRooftop(newUserSelection);
        const formularAirTypes: Array<number> = selectedRooftop && selectedRooftop.physics
            ? selectedRooftop.physics.map((item: Physics) => item.airType)
                .filter((value, index, self) => self.indexOf(value) === index)
            : []

        if (selectedRooftop !== null) {
            return formularAirTypes.map(formularAirType => airTypes.filter((airType: Option) => {
                return airType.id === formularAirType;
            })[0]);
        }

        return [];
    }

    private filterModels(transitions: TransitionProduct[]): Option[] {
        let validIds = transitions.map(item => item.model);

        return this.dataSet.options.models.filter(item => validIds.includes(item.id));
    }

    private filterAssemblys(transitions: TransitionProduct[]): Option[] {
        let validIds = transitions.map(item => item.assembly);

        return this.dataSet.options.assemblys.filter(item => validIds.includes(item.id));
    }

    private filterDn(
        userSelection: UserSelection,
        transition: ConfigurationProduct<TransitionProduct> | null
    ): Options['dn'] {
        const rooftopProduct: RooftopProduct | null = this.getRooftop(userSelection);

        if (rooftopProduct
            && rooftopProduct.system === SYSTEM_ROUND
            && rooftopProduct.prices && rooftopProduct.prices.length > 0
        ) {
            const pricesWithDn: Prices[] = rooftopProduct.prices.filter((price: Prices) => price.DN);

            if (pricesWithDn.length > 0) {

                // if system is non-insulated, no exceptions are expected
                if (userSelection.insulation === NONINSULATED) {
                    return pricesWithDn.map(price => price.DN!)
                }

                // else check if DNs are included in transition.prices,
                // because insulated transitionDNs can differ from non-insulated ones
                return this.compareWithAvailableTransitionDns(pricesWithDn, transition);
            }
            return []
        }
        return []
    }

    private filterD2(
        userSelection: UserSelection,
        prices: Array<Prices> | null
    ): Options['d2'] {

        const rooftopProduct: RooftopProduct | null = this.getRooftop(userSelection);

        if (userSelection.insulation !== NONINSULATED
            && rooftopProduct
            && rooftopProduct.system === SYSTEM_ROUND
            && rooftopProduct.prices && rooftopProduct.prices.length > 0
        ) {
            // --> if rooftop is exceptional (available transition D2s are considered in rooftop.prices),
            // return D2 for selected diameter from rooftop.prices
            if (EXCEPTIONAL_ROOFTOPS.includes(rooftopProduct.id)) {
                return this.retrieveD2ForExceptionalRooftops(rooftopProduct, userSelection.diameter)
            }
            if (prices && prices.length > 0) {
                return prices.filter(
                    price => price.DN! === userSelection.diameter
                ).map(price => price.D2!)
            }
        }
        return []
    }

    private retrieveD2ForExceptionalRooftops(rooftopProduct: RooftopProduct, diameter: number): number[] {

        let filteredPrices = rooftopProduct.prices.filter(
            price => price.DN === diameter
        )

        return filteredPrices.map(price => price.D2!)
    }

    private compareWithAvailableTransitionDns(
        pricesArray: Prices[],
        transition: ConfigurationProduct<TransitionProduct> | null
    ): number[] {
        const transitionPrices = transition && transition.product ? transition.product.prices : null;

        if (transitionPrices) {
            const dnArray = pricesArray.map(price => price.DN!);

            return dnArray.filter(dn => transitionPrices.map(price => price.DN).includes(dn))
        }
        return []
    }

    private replaceRooftopIfNecessary(rooftops: RooftopProduct[], currentId: string | null): string | null {
        if (rooftops.filter(item => item.id === currentId).length === 0) {
            if (rooftops.length > 0) {
                return rooftops[0].id;
            } else {
                return null;
            }
        }

        return currentId;
    }

    private replacePurposeIfNecessary(possiblePurposes: Option[], currentPurpose: number | null): number | null {
        if (possiblePurposes.filter(purpose => purpose.id === currentPurpose).length === 0) {
            if (possiblePurposes.length > 0) {
                return possiblePurposes[0].id;
            } else {
                return null;
            }
        }

        return currentPurpose;
    }

    private replaceModelIfNessessary(transitions: TransitionProduct[], currentId: number | null): number | null {
        if (transitions.filter(item => item.model === currentId).length === 0) {
            if (transitions.length > 0) {
                return transitions[0].model;
            } else {
                return null;
            }
        }

        return currentId;
    }

    private replaceAssemblyIfNecessary(transitions: TransitionProduct[], currentId: number | null): number | null {
        if (transitions.filter(item => item.assembly === currentId).length === 0) {
            if (transitions.length > 0) {
                return transitions[0].assembly;
            } else {
                return null;
            }
        }

        return currentId;
    }

    private replaceExtrasIfNecessary(
        newUserSelection: UserSelection,
        oldUserSelection: UserSelection,
        newPossibleExtras: Array<Product>,
        oldPossibleExtras: Array<Product>,
        usersExtras: SelectedExtras
    ) {
        if (oldUserSelection.rooftop !== newUserSelection.rooftop
            || JSON.stringify(oldPossibleExtras) !== JSON.stringify(newPossibleExtras)
        ) {
            return newPossibleExtras.map(extra => extra.id);
        }

        return usersExtras
    }

    private createConfiguration(userSelection: UserSelection) {
        let transition = this.selectTransition(userSelection);
        let rooftop = this.getRooftop(userSelection);

        if (rooftop && transition) {
            return this.createConfigurationForProducts(rooftop, transition, userSelection);
        }

        return ProductStateBuilder.emptyConfiguration;
    }

    private createConfigurationForProducts(
        rooftop: RooftopProduct,
        transition: TransitionProduct,
        userSelection: UserSelection
    ): ConfigurationProducts {
        let extras: Product[] = this.selectedExtras(userSelection);

        return {
            state: CONFIGURATION_STATE.VALIDE,
            rooftop: this.createConfigurationForProduct<RooftopProduct>(rooftop, userSelection),
            extras: extras.map(extra => this.createConfigurationForProduct<Product>(extra, userSelection)),
            transition: this.createConfigurationForProduct<TransitionProduct>(transition, userSelection)
        };
    }

    private selectedExtras(userSelection: UserSelection): Product[] {
        const possibleExtras = this.getPossibleExtras(userSelection);

        return possibleExtras.filter((extra: Product) => {
            return userSelection.extras.find((selectedExtraId: string) => extra.id === selectedExtraId);
        })
    }

    private createConfigurationForProduct<T>(
        product: T & Product,
        userSelection: UserSelection
    ): ConfigurationProduct<T> {

        // d2 von Transition prices holen
        let insulationMm = 0;
        let d2 = userSelection.d2 ? `${userSelection.d2}` : '';
        if (instanceOfTransitionProduct(product))
        {
            let d2Number = `${this.getD2ForTransition(product, userSelection)}`
            d2 = d2Number ? `${d2Number}` : '';
        }
        else if (instanceOfRooftopProduct(product))
        {
            // Insulation MM only added for the rooftop
            insulationMm = userSelection.insulationMm;
        }

        return {
            id: product.id,
            article: Namer.generateArticleName(product.purchaseId, userSelection, d2, insulationMm),
            price: null,
            state: PRODUCT_STATE.AVAILABLE,
            product: product,
        }
    }

    private getD2ForTransition(transition: TransitionProduct, userSelection: UserSelection)
    {
        let d2 = userSelection.d2 ? userSelection.d2 : null;

        const pricesWithDn: Prices[] = transition.prices.filter((price: Prices) => price.DN);
        if (pricesWithDn.length > 0) {
            pricesWithDn.forEach(function (item, index) {
                if (item.DN === userSelection.diameter) {
                    d2 = item.D2 ?? null;
                }
            });
        }

        return d2;
    }

    private availableInsulationIds(userSelection: UserSelection): (number | null)[] {
        return this.dataSet.products.transitions
            .filter(transition => {
                return transition.system === userSelection.system &&
                    transition.roofType === userSelection.roofType &&
                    transition.roofAngle === userSelection.roofAngle &&
                    transition.purpose === userSelection.purpose;
            })
            .map(transition => transition.insulation)
            .filter((value, index, self) => self.indexOf(value) === index);
    }

    private getPossibleRooftops(userSelection: UserSelection): RooftopProduct[] {

        return this.dataSet.products.rooftops.filter(item => {

            if (item.system !== userSelection.system) {
                return false;
            }
            return userSelection.airType !== null && item.airType === userSelection.airType;
        });
    }

    private getPossibleTransitions(userSelection: UserSelection): TransitionProduct[] {
        let rooftop = this.getRooftop(userSelection);

        return this.dataSet.products.transitions.filter(item => {
            return item.system === userSelection.system &&
                item.roofType === userSelection.roofType &&
                item.roofAngle === userSelection.roofAngle &&
                item.insulation === userSelection.insulation &&
                item.purpose === userSelection.purpose &&
                rooftop !== null &&
                (([1, 2].includes(item.assembly) && rooftop.channelProfile) ||
                ([0, 3, 4, 5].includes(item.assembly) && !rooftop.channelProfile));
        });
    }

    private replaceAirTypeForCalcIfNecessary(
        newUserSelection: UserSelection,
        oldUserSelection: UserSelection,
        filteredAirTypesForCalc: Option[]
    ): number {
        if (newUserSelection.rooftop !== oldUserSelection.rooftop) {
            const rooftop = this.getRooftop(newUserSelection);

            if (rooftop !== null && rooftop.airType !== null) {
                return rooftop.airType === AIRTYPE.EXHAUST_AND_OUTSIDE
                    ? filteredAirTypesForCalc[0].id
                    : rooftop.airType;
            }
        }

        return newUserSelection.airTypeForCalculations
    }

    private getPossibleExtras(userSelection: UserSelection): Product[] {
        let transition = this.selectTransition(userSelection);
        let rooftop = this.getRooftop(userSelection);

        if (rooftop && transition) {
            return this.selectExtras(rooftop, transition);
        }
        return [];
    }

    private selectTransition(userSelection: UserSelection): TransitionProduct | null {
        let selection = this.getPossibleTransitions(userSelection).filter(item => {
            return item.assembly === userSelection.assembly
                && item.model === userSelection.model
                && item.purpose === userSelection.purpose;
        });

        if (selection.length > 0) {
            return selection[0];
        }

        return null;
    }

    private selectExtras(rooftop: RooftopProduct, transition: TransitionProduct) {
        let filtered = this.dataSet.configurations.filter(item => {
            return (item.transitions.length === 0 || item.transitions.includes(transition.id)) &&
                (item.rooftops.length === 0 || item.rooftops.includes(rooftop.id));
        });

        let ids = filtered.map(item => {
            return item.extra;
        });

        return this.dataSet.products.extras.filter(extra => {
            return ids.includes(extra.id);
        });
    }

    private getRooftop(userSelection: UserSelection): RooftopProduct | null {
        let filtered = this.dataSet.products.rooftops.filter(item => {
            return item.id === userSelection.rooftop;
        });

        if (filtered.length > 0) {
            return filtered[0];
        }

        return null;
    }

    private replaceD2IfNecessary(newUserSel: UserSelection): number | null {
        const rooftopProduct: RooftopProduct | null = this.getRooftop(newUserSel);

        if (rooftopProduct
            && rooftopProduct.system === SYSTEM_ROUND
            && rooftopProduct.prices && rooftopProduct.prices.length > 0
        ) {
            // If exceptional rooftop, d2 must be included in purchaseId for noninsulated systems, too
            if (EXCEPTIONAL_ROOFTOPS.includes(newUserSel.rooftop ? newUserSel.rooftop : '')) {
                return this.retrieveD2ForExceptionalRooftops(rooftopProduct, newUserSel.diameter)[0]
            }
            // If rooftop is not exceptional and system is noninsulated return null
            if (newUserSel.insulation === NONINSULATED) {
                return null
            } else {
                let transition = this.selectTransition(newUserSel);
                const possibleD2s = this.filterD2(newUserSel, transition ? transition.prices : null);

                // If possibleD2 includes userSel, don't replace otherwise replace with smallest value
                return possibleD2s.includes(newUserSel.d2 ? newUserSel.d2 : 0)
                    ? newUserSel.d2
                    : possibleD2s[0]
            }
        }

        return newUserSel.d2
    }

    private replaceLouverSelectionIfNecessary(newUserSel: UserSelection, oldUserSel: UserSelection): number {
        const {
            sizeA,
            sizeB,
            louverSelection
        } = newUserSel;

        const rooftop = this.getRooftop(newUserSel);

        if (rooftop !== null && rooftop.minAB !== null && rooftop.maxAB !== null) {
            const isSizeSelValid = sizeA >= rooftop.minAB && sizeA <= rooftop.maxAB
                && sizeB >= rooftop.minAB && sizeB <= rooftop.maxAB;

            if (isSizeSelValid) {
                const standardLouverCnt: number = getStandardLouverCnt(sizeA, sizeB);

                const didSizeChange = newUserSel.sizeA !== oldUserSel.sizeA ||
                    newUserSel.sizeB !== oldUserSel.sizeB;

                return didSizeChange ? standardLouverCnt : louverSelection;
            }
        }
        return louverSelection;
    }

    private resetLouverSelIntModifiedIfNecessary(newUserSel: UserSelection, oldUserSel: UserSelection): boolean {
        let didSizeChange = newUserSel.sizeA !== oldUserSel.sizeA ||
            newUserSel.sizeB !== oldUserSel.sizeB;

        let wasLouverSelInternallyModified = newUserSel.louverSelection !== oldUserSel.louverSelection;

        return didSizeChange && wasLouverSelInternallyModified;
    }

    private resetRoofAngleValueWarningIfNecessary(newUserSel: UserSelection): boolean {
        let threshold: number = 45;
        let newAngleValue: number = newUserSel.roofAngleValue;

        return newAngleValue > threshold;
    }
}
