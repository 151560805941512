import * as React from 'react';
import * as Action from '../../../../store/actions';
import { Form, Statistic, Label } from 'semantic-ui-react';
import { throttle } from 'lodash';
import {useTranslation} from "react-i18next";
import {ConfigurationProduct, TransitionProduct} from "../../../../types/types";
import I18n from "../../../../i18n/i18n";




type Props = {
    transitionKey: string | undefined,
    label: string,
    value: number | null,
    setParameter: typeof Action.setParameter,
    warningShouldBeDisplayed: boolean,
    min: number | null,
    max: number | null,
    transition: ConfigurationProduct<TransitionProduct> | null,
};

function RoofAngleSliderField(props: Props) {

  const { t } = useTranslation();

  const throtteledUpdateParameters = throttle(
      (value: number) => {
        props.setParameter('roofAngle', value === 0 ? '1' : '2');
        props.setParameter('roofAngleValue', value.toString());
      },
      250,
      {
        leading: false,
        trailing: true
      }
  );

  const onChangeInput = (event: { persist: () => void; currentTarget: { value: string; }; }) => {
    event.persist();
    throtteledUpdateParameters(Number.parseInt(event.currentTarget.value))
  };

  let errors: string[] = []
  let warnings: string[] = [];

  let minAllowed = props.transition?.product?.minAngle ?? 0;
  let maxAllowed =  props.transition?.product?.maxAngle ?? 55;

  let selectedValue = props.value ?? 0;
  if (selectedValue < minAllowed) {
    errors.push(I18n.t(
        'configurator.component.filterForm.error.minAngle',
        { value: minAllowed }))

  } else if (selectedValue > maxAllowed) {
    errors.push(I18n.t(
        'configurator.component.filterForm.error.maxAngle',
        { value: maxAllowed }))
  }

  if (props.warningShouldBeDisplayed && errors.length === 0) {
    warnings.push(t('configurator.component.filterForm.warning.roofAngleStandardValueExceeded'));
  }

  return (
      <Form.Field>
        <label>{props.label}</label>
        <Statistic.Group size="mini" widths="one">
          <Statistic inverted={true} color="grey">
            <Statistic.Value>{selectedValue} &deg;</Statistic.Value>
          </Statistic>
        </Statistic.Group>

        <input
            key={props.transitionKey}
            type="range"
            min={0}
            max={60}
            style={{width: '100%'}}
            defaultValue={selectedValue.toString()}
            onChange={onChangeInput}
        />
        {errors.map(message => (
            <Label key={message} basic={true} color="red" pointing="above">{message}</Label>
        ))}
        {warnings.map(warning => (
            <Label key={warning} basic={true} color="orange" pointing="above">{warning}</Label>
        ))}
      </Form.Field>
  )
}

export default RoofAngleSliderField;