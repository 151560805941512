import {
    ApiConfigurationProducts,
    ApiConfigurationProduct,
    ConfigurationProducts, RooftopProduct, TransitionProduct, Product, ProductPrices, ConfigurationProduct
} from '../types/types';

export default class ApiObjectConverter {

    public static createConfigurationProducts(
        products: ConfigurationProducts,
        prices: ProductPrices
    ): ApiConfigurationProducts {
        if (!products) {
            throw new Error('Rooftop and Transition must be set');
        }
        if (products.rooftop && products.transition) {
            return {
                rooftop: this.getApiConfigurationProduct<RooftopProduct>(products.rooftop, prices),
                extras: products.extras.map((item) => this.getApiConfigurationProduct<Product>(item, prices)),
                transition: this.getApiConfigurationProduct<TransitionProduct>(products.transition, prices)
            };
        }
        throw new Error(`Illegal product selection, product doesn't exist in DB`);
    }

    static getApiConfigurationProduct<T>(
        product: ConfigurationProduct<T>,
        prices: ProductPrices
    ): ApiConfigurationProduct {
        if (product) {
            return {
                id: product.id,
                article: product.article,
                state: product.state,
                price: this.getProductPrice(product.id, prices)
            }
        }
        throw new Error('Productselection is invalid');
    }

    static getProductPrice(id: string, prices: ProductPrices): string | null {
        let filteredPrice = prices.filter((price) => price.id === id);

        if (filteredPrice.length === 1) {
            let productPrice: number | null = filteredPrice[0].price;

            return productPrice !== null
                ? '€ ' + productPrice.toFixed(2).replace('.', ',')
                : null;
        }
        return null;
    }
}
