// Do not edit.
import { ShaderStore } from "../../Engines/shaderStore";

const name = "pbrBlockFinalUnlitComponents";
const shader = `var finalDiffuse: vec3f=diffuseBase;finalDiffuse*=surfaceAlbedo.rgb;finalDiffuse=max(finalDiffuse,vec3f(0.0));finalDiffuse*=vLightingIntensity.x;var finalAmbient: vec3f=vAmbientColor;finalAmbient*=surfaceAlbedo.rgb;var finalEmissive: vec3f=vEmissiveColor;
#ifdef EMISSIVE
var emissiveColorTex: vec3f=texture2D(emissiveSampler,vEmissiveUV+uvOffset).rgb;
#ifdef GAMMAEMISSIVE
finalEmissive*=toLinearSpace(emissiveColorTex.rgb);
#else
finalEmissive*=emissiveColorTex.rgb;
#endif
finalEmissive*= vEmissiveInfos.y;
#endif
finalEmissive*=vLightingIntensity.y;
#ifdef AMBIENT
var ambientOcclusionForDirectDiffuse: vec3f=mix( vec3f(1.),aoOut.ambientOcclusionColor,vAmbientInfos.w);
#else
var ambientOcclusionForDirectDiffuse: vec3f=aoOut.ambientOcclusionColor;
#endif
finalAmbient*=aoOut.ambientOcclusionColor;finalDiffuse*=ambientOcclusionForDirectDiffuse;
`;
// Sideeffect
ShaderStore.IncludesShadersStoreWGSL[name] = shader;
/** @internal */
export const pbrBlockFinalUnlitComponents = { name, shader };
