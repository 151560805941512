export default {
    de: {
        mobileText: {
            projects: {
                component: {
                    projectDetailView: {
                        changeProjectButton: 'zurück',
                        projectDeleteButton: 'löschen'
                    },
                    addConfigurationModal: {
                        modalOpenButton: 'hinzufügen',
                    }
                },
            },
            configurator: {
                component: {
                    productView: {
                        tab: {
                            productRenderer: '3D',
                            productList: 'Details',
                            project: 'Projekt'
                        },
                    },
                },
            },
        },
        notifications: {
            unexpectedError: 'Es ist ein unerwarteter Fehler aufgetreten.',
            project: {
                created: 'Das Projekt wurde erfolgreich angelegt.',
                removed: 'Das Projekt wurde entfernt.',
                updated: 'Änderungen wurden erfolgreich gespeichert.'
            },
            projectConfiguration: {
                created: 'Die Konfiguration wurde dem Projekt hinzugefügt',
                removed: 'Die Konfiguration wurde aus dem Projekt entfernt'
            },
            notAuthenticated: 'Please log in',
        },
        common: {
            component: {
                header: {
                    login: 'Einloggen',
                    logout: 'Ausloggen'
                },
                footer: {
                    imprint: {
                        label: 'Impressum',
                        url: 'http://www.rokaflex.de/de/impressum'
                    },
                    privacy: {
                        label: 'Datenschutzerklärung',
                        url: 'https://www.rokaflex.de/de/datenschutz'
                    },
                    terms: {
                        label: 'AGB',
                        url: 'http://www.rokaflex.de/de/agb'
                    }
                },
                deleteButton: {
                    modal: {
                        content: 'Sind Sie sicher?',
                        confirmButton: 'Ja',
                        cancelButton: 'Nein'
                    }
                }
            }
        },
        user: {
            formValidatorMessages: {
                privacyCheckbox: 'Um sich registrieren zu können, ist ihre Zustimmung notwendig',
                required: 'Dieser Wert sollte nicht leer sein.',
                max4096: 'Diese Zeichenkette ist zu lang. Sie sollte höchstens 4096 Zeichen haben.',
                max128: 'Diese Zeichenkette ist zu lang. Sie sollte höchstens 128 Zeichen haben.',
                max255: 'Diese Zeichenkette ist zu lang. Sie sollte höchstens 255 Zeichen haben.',
                max16: 'Diese Zeichenkette ist zu lang. Sie sollte höchstens 16 Zeichen haben.',
                max64: 'Diese Zeichenkette ist zu lang. Sie sollte höchstens 64 Zeichen haben.',
                min6: 'Diese Zeichenkette ist zu kurz. Sie sollte mindestens 6 Zeichen haben.',
                min2: 'Diese Zeichenkette ist zu kurz. Sie sollte mindestens 2 Zeichen haben.',
                emailNotValid: 'Dieser Wert ist keine gültige E-Mail-Adresse.'
            },
            component: {
                login: {
                    error: {
                        header: 'Fehler',
                        loginData: 'Fehlerhafte Zugangsdaten',
                    },
                    label: {
                        email: 'E-Mail',
                        password: 'Passwort'
                    },
                    loginButton: 'Login',
                    registerButton: 'Registrieren',
                    resetPassword: 'Passwort vergessen?'
                },
                resetPassword: {
                    requestReset: {
                        header: 'Neues Passwort anfordern',
                        submitButton: 'Anfrage senden',
                        info: {
                            header: 'Vorgehen',
                            1: 'Wir senden Ihnen eine E-Mail an die von Ihnen unten eingegebene Adresse.',
                            2: 'Folgen Sie dem mitgesendeten Link.',
                            3: 'Geben Sie Ihr neues Passwort ein.',
                        },
                        success: {
                            headline: 'Ihre Anfrage war erfolgreich!',
                            copytext: 'Sofern Ihre E-Mail-Adresse einem gültigen Account zugeordnet werden kann, ' +
                            'erhalten Sie in Kürze eine E-Mail von uns. ' +
                            'Bitte folgen Sie den Anweisungen in der E-Mail.'
                        }
                    },
                    sendButton: 'Neues Passwort senden',
                    password: 'Neues Passwort:',
                    passwordRepeat: 'Bitte wiederholen Sie Ihr neues Passwort:',
                    validation: 'Die Passworteingaben sind nicht übereinstimmend. Bitte wiederholen Sie die Eingabe.',
                    success: {
                        headline: 'Ihr neues Passwort wurde erfolgreich gesetzt',
                        copytext: 'Sie können sich jetzt mit Ihrem neuen Passwort einloggen. Vielen Dank.'
                    },
                },
                registration: {
                    label: {
                        email: 'E-Mail',
                        gender: 'Anrede',
                        firstName: 'Vorname',
                        lastName: 'Nachname',
                        company: 'Unternehmen',
                        streetNr: 'Straße und Hausnummer',
                        zip: 'Postleitzahl',
                        city: 'Stadt',
                        plainPassword: 'Passwort',
                        newsletter: 'Ich möchte über Produktneuheiten und Updates informiert werden.'
                    },
                    privacy: {
                        label: `Sie erklären sich damit einverstanden, dass Ihre Daten für die Registrierung 
                        verarbeitet werden. Informationen und Widerrufshinweise finden Sie in unserer `,
                        link: {
                            url: 'https://www.rokaflex.de/de/datenschutz',
                            text: ' Datenschutzerklärung.'
                        }
                    },
                    gender: {
                        male: 'Herr',
                        female: 'Frau'
                    },
                    registerButton: 'Registrieren',
                    success: {
                        headline: 'Ihre Registrierung war erfolgreich!',
                        copytext: 'Sie können sich nun einloggen.'
                    }
                }
            }
        },
        registrationPage: {
            headline: 'Registrierung'
        },
        configurator: {
            calculator: {
                roundSystemCalculator: {
                    error: {
                        maxDiameter: 'Nennweite bis max. {{max}}'
                    }
                }
            },
            configuratorPage: {
                invalidConfiguration: {
                    header: 'Ungültige Konfiguration',
                    content: 'Für die eingestellten Parameter konnte keine Konfiguration gefunden werden.'
                },
            },
            component: {
                productView: {
                    notAuthenticated: {
                        header: 'Bitte loggen Sie sich ein',
                        content: 'Für den Zugriff auf Ihre Projekte müssen Sie eingeloggt sein.'
                    },
                    tab: {
                        productRenderer: '3D-Ansicht',
                        productList: 'Detail-Ansicht',
                        noProject: 'Projekt auswählen'
                    }
                },
                filterForm: {
                    section: {
                        options: 'Parameter',
                        rooftops: 'Dachhauben',
                        extras: {
                            header: 'Zubehör',
                            warning: 'Empfohlenes Zubehör wurde entfernt!'
                        },
                        calculator: 'Aerodynamik'
                    },
                    label: {
                        airType: 'Luftart',
                        diameter: 'Nennweite',
                        insulation: 'Isolierung',
                        purpose: 'Dachaufsatz',
                        roofType: 'Dachart',
                        roofAngle: 'Dachneigung',
                        variation: 'Ausführung',
                        system: 'System',
                        louverSelection: 'Lamellenanzahl',
                        assembly: 'Montage',
                        extDiameter: 'Außendurchmesser',
                        model: 'Ausführung'
                    },
                    error: {
                        min: 'Der minimal zulässige Wert für die ausgewählte Dachhaube ist {{value}}',
                        max: 'Die maximal zulässige Wert für die ausgewählte Dachhaube ist {{value}}',
                        currentDiameter: 'Der Wert {{value}} ist für die ausgewählte Dachhaube nicht verfügbar',
                        currentExtDiameter: 'Der Wert {{value}} ist für die ausgewählte Dachhaube nicht verfügbar',
                        minAngle: 'Der minimal zulässige Wert für die Dachneigung ist {{value}}',
                        maxAngle: 'Der maximal zulässige Wert für die Dachneigung ist {{value}}',
                    },
                    warning: {
                        louverSelectionModified: 'Durch Ihre Auswahl wurde der Wert Lamellenanzahl geändert!',
                        roofAngleStandardValueExceeded: 'Abweichung vom Standard. Aufschläge werden berechnet.'
                    },
                    help: {
                        louverSelection: 'Die Abbildung zeigt eine beispielhafte Standardkonfiguration'
                    }
                },
                calculator: {
                    label: {
                        flowRate: 'Volumenstrom',
                        loudness: 'Schalldruckpegel',
                        pressureLoss: 'Druckverlust',
                        speed: 'Geschwindigkeit',
                        calculatedFor: 'Berechnet für',
                    },
                    error: {
                        airSpeedMin: 'Luftgeschwindigkeit sollte {{min}} m/s nicht unterschreiten',
                        airSpeedMax: 'Luftgeschwindigkeit sollte {{max}} m/s nicht überschreiten'
                    }
                }
            }
        },
        projects: {
            component: {
                addConfigurationModal: {
                    modalOpenButton: 'Zu Projekt hinzufügen',
                    header: 'Konfiguration hinzufügen',
                    label: {
                        lvPos: 'LV-Pos.',
                        remark: 'Anmerkung',
                        quantity: 'Stück'
                    },
                    validation: {
                        quantity: {
                            required: 'Stückzahl wird benötigt',
                            max: 'Die maximale Stückzahl ist 1000',
                            invalid: 'Ungültige Eingabe',
                            number: 'Stückzahl muss ein nummerischer Wert sein'
                        }
                    },
                    cancelButton: 'Abbrechen',
                    createButton: 'Hinzufügen',
                    priceOnRequest: 'auf Anfrage'
                },
                createProjectModal: {
                    modalOpenButton: 'Projekt erstellen',
                    header: 'Projekt erstellen',
                    label: {
                        title: 'Titel',
                        customer: 'Kunde',
                        customerId: 'Kundennr.',
                        job: 'Auftrag',
                        field: 'Baustelle',
                        agent: 'Bearbeiter',
                        date: 'Datum',
                    },
                    cancelButton: 'Abbrechen',
                    createButton: 'Erstellen'
                },
                projectList: {
                    empty: {
                        header: 'Es wurde noch keine Projekte angelegt',
                        content: 'Legen Sie Ihr erstes Projekt über den Button "Projekt erstellen" an'
                    }
                },
                projectCard: {
                    configuration: '{{count}} Produkt-Konfigurationen'
                },
                projectConfigurationList: {
                    noConfigurations: {
                        header: 'Leeres Projekt',
                        content: 'In diesem Projekt wurden noch keine Produkt-Konfigurationen hinterlegt. Klicken ' +
                        'Sie in der 3D-Ansicht auf den Button "Zu Projekt hinzufügen", um die dort angezeigte ' +
                        'Konfiguration Ihrem ausgewählten Projekt hinzuzufügen.'
                    },
                    label: {
                        lvPos: 'LV-Pos.',
                        remark: 'Anmerkung',
                        quantity: 'Stück',
                        price: 'Preis',
                        priceOnRequest: 'auf Anfrage'
                    },
                    deleteButton: 'Löschen'
                },
                projectDetailView: {
                    updateButton: 'Änderungen speichern',
                    changeProjectButton: 'Projekt wechseln',
                    projectDeleteButton: 'Projekt löschen',
                    pdfExport: {
                        header: 'Download',
                        withPricesButton: 'Mit Preisen',
                        withoutPricesButton: 'Ohne Preise'
                    },
                    details: {
                        header: 'Projektdetails',
                        label: {
                            job: 'Auftrag',
                            agent: 'Bearbeiter',
                            field: 'Baustelle',
                            date: 'Datum',
                            customer: 'Kunde',
                            customerId: 'Kundennr.'
                        }
                    },
                    configurations: {
                        valid: {
                            header: 'Produktkonfigurationen'
                        },
                        invalid: {
                            header: 'Ungültige Produktkonfigurationen',
                            product: {
                                notAvailable: 'Artikel derzeit nicht verfügbar.',
                                deleted: 'Artikel aus dem Sortiment entfernt.'
                            }
                        }
                    }
                }
            }
        }
    },
    en: {
        mobileText: {
            projects: {
                component: {
                    projectDetailView: {
                        changeProjectButton: 'back',
                        projectDeleteButton: 'delete'
                    },
                    addConfigurationModal: {
                        modalOpenButton: 'add',
                    }
                },
            },
            configurator: {
                component: {
                    productView: {
                        tab: {
                            productRenderer: '3D',
                            productList: 'Details',
                            project: 'Project'
                        },
                    },
                },
            },
        },
        notifications: {
            unexpectedError: 'An unexpected error has occurred.',
                project: {
                created: 'The project has been created.',
                    removed: 'The project has been deleted.',
                    updated: 'Changes have been saved.'
            },
            projectConfiguration: {
                created: 'The configuration has been added to the project',
                    removed: 'The configuration has been deleted from the project'
            },
            notAuthenticated: 'Bitte loggen Sie sich ein',
        },
        common: {
            component: {
                header: {
                    login: 'Login',
                        logout: 'Logout'
                },
                footer: {
                    imprint: {
                        label: 'Imprint',
                            url: 'http://www.rokaflex.de/en/imprint'
                    },
                    privacy: {
                        label: 'Data Protection Notice',
                        url: 'https://www.rokaflex.de/en/data-protection-notice'
                    },
                    terms: {
                        label: 'Terms and Conditions',
                            url: 'http://www.rokaflex.de/en/general-terms-and-conditions'
                    }
                },
                deleteButton: {
                    modal: {
                        content: 'Are you sure?',
                            confirmButton: 'Yes',
                            cancelButton: 'No'
                    }
                }
            }
        },
        user: {
            formValidatorMessages: {
                privacyCheckbox: 'Please confirm to proceed with your registration.',
                required: 'This value should not be blank.',
                max4096: 'This value is too long. It should have 4096 characters or less.',
                max128: 'This value is too long. It should have 128 characters or less.',
                max255: 'This value is too long. It should have 255 characters or less.',
                max16: 'This value is too long. It should have 16 characters or less.',
                max64: 'This value is too long. It should have 64 characters or less.',
                min6: 'This value is too short. It should have 6 characters or more.',
                min2: 'This value is too short. It should have 2 characters or more.',
                emailNotValid: 'This value is not a valid email address.'
            },
            component: {
                login: {
                    error: {
                        header: 'Error',
                        loginData: 'Incorrect login details',
                    },
                    label: {
                        email: 'Email',
                        password: 'Password'
                    },
                    loginButton: 'Login',
                    registerButton: 'Register',
                    resetPassword: 'Forgot password?'
                },
                resetPassword: {
                    requestReset: {
                        header: 'Request new password',
                        submitButton: 'Send request',
                        info: {
                            header: 'Next steps:',
                            1: 'We will send you an e-mail to the address you entered below.',
                            2: 'Follow the link provided.',
                            3: 'Enter your new password.',
                        },
                        success: {
                            headline: 'Your request was successful!',
                            copytext: 'If your e-mail address can be assigned to a valid account, ' +
                            'you will shortly receive an e-mail from us. Please follow the further procedure ' +
                            'described in the e-mail.'
                        }
                    },
                    sendButton: 'Send new password',
                    password: 'New Password:',
                    passwordRepeat: 'Please repeat your new password:',
                    validation: 'The passwords do not match. Please try again!',
                    success: {
                        headline: 'Your new password has been saved successfully.',
                        copytext: 'You can log in with your new password now. Thank you!'
                    },
                },
                registration: {
                    label: {
                        email: 'Email',
                        gender: 'Salutation',
                        firstName: 'First name',
                        lastName: 'Surname',
                        company: 'Company',
                        streetNr: 'Street and house number',
                        zip: 'Zip code',
                        city: 'City',
                        plainPassword: 'Password',
                        newsletter: 'I would like to be informed about new products and updates.'
                    },
                    privacy: {
                        label: `You agree that your data will be processed for registration. Information and 
                        disclaimer can be found in our `,
                        link: {
                            url: 'https://www.rokaflex.de/de/datenschutz',
                            text: ' Data Protection Notice.'
                        }
                    },
                    gender: {
                        male: 'Mr',
                        female: 'Ms'
                    },
                    registerButton: 'Register',
                        success: {
                        headline: 'You have been successfully registered!',
                            copytext: 'You can now log in.'
                    }
                }
            }
        },
        registrationPage: {
            headline: 'Registration'
        },
        configurator: {
            calculator: {
                roundSystemCalculator: {
                    error: {
                        maxDiameter: 'Nominal diameter up to max. {{max}}'
                    }
                }
            },
            configuratorPage: {
                invalidConfiguration: {
                    header: 'Invalid configuration',
                        content: 'No configuration could be found for the set parameters.'
                },
            },
            component: {
                productView: {
                    notAuthenticated: {
                        header: 'Please log in',
                            content: 'You must be logged in to gain access to your projects.'
                    },
                    tab: {
                        productRenderer: '3D-View',
                            productList: 'Detail-View',
                            noProject: 'Select project'
                    }
                },
                filterForm: {
                    section: {
                        options: 'Parameters',
                        rooftops: 'Roof hoods',
                        calculator: 'Aerodynamic',
                        extras: {
                            header: 'Accessory',
                            warning: 'Recommended accessories removed!'
                        }
                    },
                    label: {
                        airType: 'Type of air',
                        diameter: 'Nominal diameter',
                        insulation: 'Insulation',
                        purpose: 'Purpose',
                        roofType: 'Type of roof',
                        roofAngle: 'Angle of roof',
                        variation: 'Variant',
                        system: 'System',
                        louverSelection: 'Number of slats',
                        assembly: 'Montage',
                        extDiameter: 'Outer diameter',
                        model: 'Model'
                    },
                    error: {
                        min: 'The minimum admissible value for the selected roof hood is {{value}}',
                        max: 'The maximum admissible value for the selected roof hood is {{value}}',
                        currentDiameter: 'The current value {{value}} is not available for the selected roof hood',
                        currentExtDiameter: 'The current value {{value}} is not available for the selected roof hood',
                        minAngle: 'The minimum admissible value for the angle of roof is {{value}}',
                        maxAngle: 'The maximum admissible value for the angle of roof is {{value}}',
                    },
                    warning: {
                        louverSelectionModified: 'The number of slats has been modified due to your selection!',
                        roofAngleStandardValueExceeded: 'No standard. Additional charges apply.'
                    },
                    help: {
                        louver: 'Die Abbildung zeigt eine beispielhafte Standardkonfiguration'
                    }
                },
                calculator: {
                    label: {
                        flowRate: 'Flow rate',
                        loudness: 'Loudness',
                        pressureLoss: 'Pressure loss',
                        speed: 'Speed',
                        calculatedFor: 'Calculated for',
                    },
                    error: {
                        airSpeedMin: 'The air speed should not fall below {{min}} m/s',
                            airSpeedMax: 'The air speed should not exceed {{max}} m/s'
                    }
                }
            }
        },
        projects: {
            component: {
                addConfigurationModal: {
                    modalOpenButton: 'Add to project',
                        header: 'Add configuration',
                        label: {
                        lvPos: 'LV-Pos.',
                            remark: 'Remarks',
                            quantity: 'Pieces'
                    },
                    validation: {
                        quantity: {
                            required: 'Quantity is required',
                            max: 'Max. value is 1000',
                            invalid: 'Invalid value',
                            number: 'Quantity must be a numeric value'
                        }
                    },
                    cancelButton: 'Cancel',
                        createButton: 'Add',
                        priceOnRequest: 'On request'
                },
                createProjectModal: {
                    modalOpenButton: 'Create project',
                        header: 'Create project',
                        label: {
                        title: 'Title',
                            customer: 'Customer',
                            customerId: 'Customer number',
                            job: 'Order',
                            field: 'Site',
                            agent: 'Agent',
                            date: 'Date',
                    },
                    cancelButton: 'Cancel',
                        createButton: 'Create'
                },
                projectList: {
                    empty: {
                        header: 'No projects have been created',
                            content: 'Create your first project with the "Create Project" button'
                    }
                },
                projectCard: {
                    configuration: '{{count}} Product configurations'
                },
                projectConfigurationList: {
                    noConfigurations: {
                        header: 'Empty Project',
                        content: 'No product configurations have been saved in this project. ' +
                        'Click the "Add to project" button in the 3D-view, to add the configuration ' +
                        'shown there to the project you have selected.'
                    },
                    label: {
                        lvPos: 'LV-Pos.',
                            remark: 'Remarks',
                            quantity: 'Pieces',
                            price: 'Price',
                            priceOnRequest: 'On request'
                    },
                    deleteButton: 'Delete'
                },
                projectDetailView: {
                    updateButton: 'Save changes',
                        changeProjectButton: 'Change project',
                        projectDeleteButton: 'Delete project',
                        pdfExport: {
                        header: 'Download',
                            withPricesButton: 'With prices',
                            withoutPricesButton: 'Without prices'
                    },
                    details: {
                        header: 'Project details',
                            label: {
                            job: 'Order',
                                agent: 'Agent',
                                field: 'Site',
                                date: 'Date',
                                customer: 'Customer',
                                customerId: 'Customer number.'
                        }
                    },
                    configurations: {
                        valid: {
                            header: 'Product configurations'
                        },
                        invalid: {
                            header: 'Invalid configuration',
                            product: {
                                notAvailable: 'Item currently not available.',
                                deleted: 'Item has been discontinued.'
                            }
                        }
                    }
                }
            }
        }
    }
};
