import * as React from 'react';
import { Product } from '../../types/types';
import { Card, Image } from 'semantic-ui-react';

const ProductItem = ({article, product, locale}: { article: string | null, product: Product, locale: string }) => (
    <Card>
        <Image src={product.image}/>
        <Card.Content>
            <Card.Header>
                {article}
            </Card.Header>
            <Card.Meta>
                {product.translations[locale].title}
            </Card.Meta>
            <Card.Description>
                {product.translations[locale].description}
            </Card.Description>
        </Card.Content>
    </Card>
);

export default ProductItem;