import React from 'react';
import {Container, Header, Segment} from "semantic-ui-react";
import {AuthState} from "../types/types";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ResetPasswordForm from "../forms/ResetPasswordForm";


type RegistrationPageProps = {
  authState: AuthState,
};

function ResetPasswordPage(props: RegistrationPageProps) {

  const navigate = useNavigate();
  const { t } = useTranslation();

  if (props.authState.isAuthenticated) {
    navigate('/')
  }

  return (
      <Container>
        <Header as="h1">
          Password Reset
        </Header>

        <Segment className="login-segment">
          <ResetPasswordForm />
        </Segment>
      </Container>
  );
}


export default (ResetPasswordPage);
