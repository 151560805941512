

import productReducer from './reducer/ProductReducer';
import authReducer from './reducer/AuthReducer';
import projectReducer from './reducer/ProjectReducer';
import sidebarReducer from './reducer/SidebarReducer';
import {configureStore} from "@reduxjs/toolkit";
import LanguageReducer from "./reducer/LanguageReducer";

const store = configureStore({
  reducer: {
    auth: authReducer,
    product: productReducer,
    project: projectReducer,
    sidebar: sidebarReducer,
    language: LanguageReducer,
  },
})

//syncTranslationWithStore(store);
//store.dispatch(loadTranslations(translations));
//store.dispatch(setLocale('de'));


export default store;