import { SidebarState } from '../../types/types';
import {createSlice, type PayloadAction} from "@reduxjs/toolkit";
import {SidebarHideAction, SideBarSetHideabilityAction, SidebarShowAction, SidebarToggleAction} from "../actions";

let initialState: SidebarState = {
    visible: true,
    canBeHidden: false
};



const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        show(state, action: PayloadAction<SidebarShowAction>) {
            return {
                ...state,
                visible: true
            };
        },
        hide(state, action: PayloadAction<SidebarHideAction>) {
            return {
                ...state,
                visible: false
            };
        },
        toggle(state, action: PayloadAction<SidebarToggleAction>) {
            return {
                ...state,
                visible: !state.visible
            };
        },
        setHideability(state, action: PayloadAction<SideBarSetHideabilityAction>) {

            let visible = state.visible;
            if (state.canBeHidden && !action.payload.canBeHidden && !visible) {
                visible = true;
            } else if (!state.canBeHidden && action.payload.canBeHidden && visible) {
                visible = false;
            }

            return {
                visible: visible,
                canBeHidden: action.payload.canBeHidden
            };
        },

    },
})

export const { show, hide, toggle, setHideability } = sidebarSlice.actions
export default sidebarSlice.reducer