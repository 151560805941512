import { DataSet, Product, RooftopProduct } from '../types/types';

export default class DataSetHelper {
    private dataSet: DataSet;

    public constructor(dataSet: DataSet) {
        this.dataSet = dataSet;
    }

    public getTransitionById(id: string): Product | null {
        return this.filterById(this.dataSet.products.transitions, id);
    }

    public getRooftopById(id: string): RooftopProduct | null {
        return this.filterById(this.dataSet.products.rooftops, id) as RooftopProduct;
    }

    public getExtrasByIds(ids: string[]): Array<Product | null> {
        return this.filterByIds(this.dataSet.products.extras, ids);
    }

    private filterById(products: Product[], id: string): Product | null {
        let filtered = products.filter(item => {
            return item.id === id;
        });

        if (filtered.length > 0) {
            return filtered[0];
        }

        return null;
    }

    private filterByIds(products: Product[], ids: string[]): Array<Product | null> {
        return ids.map(id => this.filterById(products, id))
    }
}
