import AbstractCalculator from './AbstractCalculator';
import { SYSTEM_RECTANGULAR, SYSTEM_ROUND } from '../../../util/constants';
import RoundSystemCalculator from './RoundSystemCalculator';
import RectangularSystemCalculator from './RectangularSystemCalculator';
import { RooftopProduct, UserSelection } from '../../../types/types';

class CalculatorFactory {
    private static calculators = {
        [SYSTEM_ROUND]: RoundSystemCalculator,
        [SYSTEM_RECTANGULAR]: RectangularSystemCalculator
    };

    public static getCalculator(
        rooftop: RooftopProduct | null,
        userSelection: UserSelection
    ): AbstractCalculator | null {

        if (rooftop) {
            // @ts-ignore
            let calculatorClass = CalculatorFactory.calculators[rooftop.system];

            if (calculatorClass) {
                return new calculatorClass(userSelection, rooftop.physics);
            }
        }

        return null;
    }
}

export default CalculatorFactory;