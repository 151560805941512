import * as React from 'react';
import { Form, Label } from 'semantic-ui-react';
import { setParameter, SetParameterValue} from '../../../../store/actions';
import {withTranslation} from "react-i18next";
import I18n from "../../../../i18n/i18n";

type RectangularSizeFieldProps = {
    label: string,
    value: number | null,
    min: number | null,
    max: number | null,
    setValue: Function
};

class RectangularSizeField extends React.Component<RectangularSizeFieldProps> {
    public render() {
        let errors: string[] = [];

        let value = this.props.value;

        if (value) {
            if (this.props.min && value < this.props.min) {
                errors.push(
                    I18n.t('configurator.component.filterForm.error.min', {value: this.props.min})
                )
            }

            if (this.props.max && value > this.props.max) {
                errors.push(
                    I18n.t('configurator.component.filterForm.error.max', {value: this.props.max})
                )
            }
        }

        return (
            <div className="field two columns">
                <Form.Input
                    label={this.props.label}
                    value={this.props.value || ''}
                    onChange={event => this.props.setValue(event.currentTarget.value)}
                />
                {errors.map(message => (
                    <Label key={message} basic={true} color="red" pointing="above">{message}</Label>
                ))}
            </div>
        );
    }
}

type RectangularSizesFieldsProps = {
    sizeA: number | null,
    sizeB: number | null,
    min: number | null,
    max: number | null,
    setParameter: typeof setParameter
};

class RectangularSizesFields extends React.Component<RectangularSizesFieldsProps> {
    public render() {
        return (
            <Form.Group widths="equal">
                <RectangularSizeField
                    label="A (mm)"
                    setValue={(value: SetParameterValue) => this.props.setParameter('sizeA', value)}
                    value={this.props.sizeA}
                    min={this.props.min}
                    max={this.props.max}
                />
                <RectangularSizeField
                    label="B (mm)"
                    setValue={(value: SetParameterValue) => this.props.setParameter('sizeB', value)}
                    value={this.props.sizeB}
                    min={this.props.min}
                    max={this.props.max}
                />
            </Form.Group>
        );
    }
}
export default withTranslation()(RectangularSizesFields)
