import * as React from 'react';
import * as Action from '../../../store/actions';
import { Button, Divider, Header } from 'semantic-ui-react';
import {Project} from "../../../types/types";
import i18n from "../../../i18n/i18n";
import ProjectDetailsForm from "./ProjectDetailsForm";


type State = {
    modified: boolean
};

type UpdateProjectProps = {
    locale: string,
    projectUpdate: typeof Action.projectUpdate,
    project: Project
};

export default class ProjectDetails extends React.Component<UpdateProjectProps, State> {
    constructor() {
        // @ts-ignore
        super();
        this.state = {
            modified: false
        };
    }

    public render() {

        const { project } = this.props;

        const initialValues = {
            title: project.title ?? '',
            customer: project.customer ?? '',
            customerId: project.customerId ?? '',
            agent: project.agent ?? '',
            date: project.date ?? '',
            field: project.field ?? '',
            job: project.job ?? '',
        };

        let updateButton: JSX.Element | null = null;
        if (this.state.modified) {
            updateButton = (
                <Button
                    type="submit"
                    form="projectDetailForm"
                    color="orange"
                    floated="right"
                    positive={true}
                    labelPosition="right"
                    icon="upload"
                    content={i18n.t('projects.component.projectDetailView.updateButton')}
                />
            );
        }

        return (
            <div>
                <Header content={i18n.t('projects.component.projectDetailView.details.header')}/>
                <ProjectDetailsForm
                    initialValues={initialValues}
                    onChange={(modified: boolean) => this.setState({ modified: modified })}
                    onSubmit={this.submit}
                    onSubmitSuccess={() => this.setState({ modified: false })}
                />
                <Divider clearing={true} hidden={true}/>

                {updateButton}

            </div>
        );
    }

    private submit = (values: Project) => {
        const path = `/projects/${this.props.project.uuid}`;
        values.title = this.props.project.title;

        return this.props.projectUpdate(this.props.locale, values, path);
    };

}