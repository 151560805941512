import React from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {FormField, Label, SemanticWIDTHS} from "semantic-ui-react";
import {useTranslation} from "react-i18next";


type FormInputProps = {
  label?: string;
  name: string;
  type?: string;
  autoComplete?: string;
  placeholder?: string;
  readonly?: boolean;
  mandatory?: boolean;
  maxLength?: number;
  width?: SemanticWIDTHS;
};

const FormInput: React.FC<FormInputProps> = ({
    label,
    name,
    type = 'text',
    autoComplete = 'off',
    placeholder = '',
    readonly = false,
    mandatory = false,
    maxLength = null,
    width = undefined,
  }) => {

  const { t } = useTranslation();

  const {
      register,
      formState: { errors },
  } = useFormContext();

  const inputValue = useWatch({
      name: name,
  });

  let inputClass = '';
  if (inputValue) {
      inputClass += ' has-value';
  }

  let wrapperClass = '';
  if (mandatory) {
    wrapperClass += ' mandatory';
  }

  let hasError = errors[name];
  let errorClass = '';
  if (hasError) {
    errorClass = ' error';
  }

  return (
      <FormField className={wrapperClass + errorClass} width={width}>

          {label &&
            <>
              <label htmlFor={name} className='input-label'>
                {label}
              </label>
            </>
          }

          <input
              type={type}
              placeholder={placeholder}
              autoComplete={autoComplete}
              id={name}
              className={'form-input' + inputClass}
              readOnly={readonly}
              maxLength={maxLength ?? undefined}
              {...register(name)}
          />

          {errors[name] && (
              <Label basic={true} color="red" pointing="above">
                {// @ts-ignore
                  t(errors[name]?.message)
                }
              </Label>
          )}
      </FormField>
    );
};

export default FormInput;