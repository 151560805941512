import * as React from 'react';
import {Button, ButtonProps, Confirm, Icon, SemanticShorthandContent} from 'semantic-ui-react';
import I18n from "../../i18n/i18n";
import {withTranslation} from "react-i18next";


type DeleteButtonProps = ButtonProps & {
    floated?: string
    size?: string
    deleteCallback: Function
    content: SemanticShorthandContent
};

type DeleteButtonState = {
    open: boolean
};

class DeleteButton extends React.Component<DeleteButtonProps, DeleteButtonState> {
    public state: DeleteButtonState = {
        open: false
    };

    public render() {
        const { content } = this.props;

        return (
            <Button
                size={this.props.size || 'mini'}
                negative={true}
                onClick={() => this.show()}
                floated={this.props.floated}
            >
                <Icon name="delete" />
                {content}
                <Confirm
                    content={I18n.t('common.component.deleteButton.modal.content')}
                    open={this.state.open}
                    confirmButton={I18n.t('common.component.deleteButton.modal.confirmButton')}
                    cancelButton={I18n.t('common.component.deleteButton.modal.cancelButton')}
                    onCancel={() => this.handleCancel()}
                    onConfirm={() => this.handleConfirm()}
                />
            </Button>
        );
    }

    private show() {
        this.setState({open: true});
    }

    private handleConfirm() {
       this.props.deleteCallback();
       this.setState({open: false});
    }

    private handleCancel() {
        this.setState({open: false});
    }
}

export default withTranslation()(DeleteButton)
