import { UserSelection } from '../types/types';

export default class Namer {
    public static generateArticleName(format: string, userSelection: UserSelection, d2: string, insulationMm: number): string {
        return format
            .replace('{dn}', userSelection.diameter ? userSelection.diameter.toString() : '')
            .replace('{a}', userSelection.sizeA ? (userSelection.sizeA + insulationMm).toString() : '')
            .replace('{b}', userSelection.sizeB ? (userSelection.sizeB + insulationMm).toString() : '')
            .replace('{angle}', userSelection.roofAngleValue ? userSelection.roofAngleValue.toString() : '')
            .replace('{louverCnt}', userSelection.louverSelection ? userSelection.louverSelection.toString() : '')
            .replace('{d2}', d2 ? `(${d2+insulationMm})` : '')
            .replace('{d2_plus_5}', d2 ? `(${d2 + insulationMm + 5})` : '');
    }
}