import React, {useState} from 'react';
import {Button, Form, Message} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {bindActionCreators} from "@reduxjs/toolkit";
import {AppState, AuthState} from "../types/types";
import {Dispatch} from "redux";
import * as Action from "../store/actions";
import {API_ERROR_UNAUTHENTICATED, callApi, LoginAction} from "../store/actions";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

type StateProps = {
  //: string
  authState: AuthState
};

type DispatchProps = {
  login: typeof Action.login
};

function LoginForm(props: StateProps & DispatchProps) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { t } = useTranslation();

  const setStateValues = (name: string, value: any) => {

    if (name === 'email') {
      setEmail(value);
    }
    if (name === 'password') {
      setPassword(value);
    }
    if (name === 'submitting') {
      setSubmitting(value);
    }
    if (name === 'error') {
      setError(value);
    }
    if (name === 'errorMesssage') {
      setErrorMessage(value);
    }

  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    setStateValues(name, value);
  }


  const submit = async () => {

    let currentResponse: Response | null = null;

    setSubmitting(false);
    setError(false);
    setErrorMessage(null);

    let body = JSON.stringify({username: email, password: password});

    try {
      const response = await callApi('POST', '/login_check', null, body).then(response => {
        setSubmitting(false);

        currentResponse = response;
        return response.json();
      }).then(json => {
            if (currentResponse === null || currentResponse.status !== 200) {
              setSubmitting(false);
              setError(true);
              setErrorMessage(json.message);
            } else {
              props.login(email, json.token);
            }
          })
          /*.catch(err => function () {
            setSubmitting(false);
            setError(false);
            setErrorMessage(null);
          });*/
    } catch (e: unknown) {
      // @ts-ignore
      let errorName = e.name;
      if (errorName === API_ERROR_UNAUTHENTICATED) {
        setSubmitting(false);
        setError(true);
        // @ts-ignore
        setErrorMessage(t('user.component.login.error.loginData'));
      }

      return null;
    }

    return null;
  }

  return (

      <div className="login-segment">
        {error &&
            <Message
              negative={true}
              icon="warning"
              header={t('user.component.login.error.header')}
              content={errorMessage}
            />
        }

        <Form loading={submitting}>
          <Form.Input
              name="email"
              label={t('user.component.login.label.email')}
              type="text"
              onChange={(e) => handleChange(e)}
          />
          <Form.Input
              name="password"
              label={t('user.component.login.label.password')}
              type="password"
              onChange={(e) => handleChange(e)}
          />

          <div>

            <Button
                type="submit"
                color="blue"
                onClick={submit}
                icon="sign in"
                labelPosition="right"
                content={t('user.component.login.loginButton')}
            />

            <Link to="/registration">
              <b>{t('user.component.login.registerButton')}</b>
            </Link>

            <div style={{display: 'inline', float: 'right'}}>
              <Link to="/require-new-password">
                <b>{t('user.component.login.resetPassword')}</b>
              </Link>
            </div>
          </div>

        </Form>
      </div>
  );
}


function mapStateToProps(state: AppState): StateProps {
  return {
    //locale: state.i18n.locale,
    authState: state.auth
  };
}

function mapDispatchToProps(dispatch: Dispatch<LoginAction>): DispatchProps {
  return bindActionCreators(
      {
        login: Action.login
      },
      dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
