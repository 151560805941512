import * as React from 'react';
import { Form, Label } from 'semantic-ui-react';
import Choice from "../../../../components/common/Choice";
import I18n from "../../../../i18n/i18n";
import {withTranslation} from "react-i18next";


interface Props {
    extDiameterValues: number[],
    currentExtDiameter: number | null,
    updateValue: (value: string) => void
}

interface State {
}

class ExternalDiameterField extends React.PureComponent<Props, State> {
    public render() {
        let errors: string[] = [];

        let extDiameter = this.props.currentExtDiameter;

        if (extDiameter) {
            if (this.props.currentExtDiameter
                && !this.props.extDiameterValues.includes(this.props.currentExtDiameter)
            ) {
                errors.push(I18n.t(
                    'configurator.component.filterForm.error.currentExtDiameter',
                    {value: this.props.currentExtDiameter}));
            }
        }

        const isDisabled = this.props.extDiameterValues.length < 2
        && this.props.currentExtDiameter ? this.props.extDiameterValues.includes(this.props.currentExtDiameter) : false;

        return (
            <Form.Field>
                <label>{I18n.t('configurator.component.filterForm.label.extDiameter')}</label>
                <Choice
                    isDisabled={isDisabled}
                    choices={this.createDiameterChoices()}
                    value={this.props.currentExtDiameter}
                    updateValue={this.props.updateValue}
                    error={errors.length > 0}
                />
                {errors.map(message => (
                    <Label key={message} basic={true} color="red" pointing="above">{message}</Label>
                ))}
            </Form.Field>
        );
    }

    private createDiameterChoices() {
        return this.props.extDiameterValues.map(value => ({
            key: value,
            value: value,
            text: value.toString()
        }));
    }
}

export default withTranslation()(ExternalDiameterField)
