import * as React from 'react';
import { Form, Label } from 'semantic-ui-react';
import Choice from "../../../../components/common/Choice";
import I18n from "../../../../i18n/i18n";
import {withTranslation} from "react-i18next";


type Props = {
    diameterValues: number[],
    currentDiameter: number | null,
    minDiameter: number | null,
    maxDiameter: number | null,
    updateValue: (value: string) => void
};

class DiameterField extends React.Component<Props> {
    public render() {
        let errors: string[] = [];

        let diameter = this.props.currentDiameter;
        if (diameter) {
            if (this.props.minDiameter && diameter < this.props.minDiameter) {
                errors.push(I18n.t(
                    'configurator.component.filterForm.error.min',
                    {value: this.props.minDiameter}));
            } else if (this.props.maxDiameter && diameter > this.props.maxDiameter) {
                errors.push(I18n.t(
                    'configurator.component.filterForm.error.max',
                    {value: this.props.maxDiameter}));
            } else if (this.props.currentDiameter && !this.props.diameterValues.includes(this.props.currentDiameter)) {
                errors.push(I18n.t(
                    'configurator.component.filterForm.error.currentDiameter',
                    {value: this.props.currentDiameter}));
            }
        }

        return (
            <Form.Field>
                <label>{I18n.t('configurator.component.filterForm.label.diameter')} (mm)</label>
                <Choice
                    choices={this.createDiameterChoices()}
                    value={this.props.currentDiameter}
                    updateValue={this.props.updateValue}
                    error={errors.length > 0}
                />
                {errors.map(message => (
                    <Label key={message} basic={true} color="red" pointing="above">{message}</Label>
                ))}
            </Form.Field>
        );
    }

    private createDiameterChoices() {
        return this.props.diameterValues.map(value => ({
            key: value,
            value: value,
            text: value.toString()
        }));
    }
}

export default withTranslation()(DiameterField)
