import * as React from 'react';
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';

type ChoiceProps = {
    choices: DropdownItemProps[],
    value: number | string | null,
    error?: boolean,
    isDisabled?: boolean,
    updateValue: (value: string) => void
};

export default class Choice extends React.PureComponent<ChoiceProps> {

    render() {

        return (
            <Dropdown
                disabled={this.props.isDisabled}
                fluid={true}
                selection={true}
                options={this.buildChoices()}
                text={this.buildText()}
                value={this.props.value as string}
                error={this.props.error || false}
                // @ts-ignore
                onChange={
                    (event: React.SyntheticEvent<HTMLDivElement>,
                     data: DropdownProps) => this.props.updateValue(data.value as string)
                }
            />
        );
    }

    private buildChoices() {
        return this.props.choices.filter(item => item.value !== this.props.value);
    }

    private buildText(): string {
        let filtered = this.props.choices.filter(item => item.value === this.props.value);

        if (filtered.length > 0) {
            return filtered[0].text as string;
        }

        return '';
    }
}
