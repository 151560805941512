
//import { I18nState } from 'react-redux-i18n';
import {Action} from "@reduxjs/toolkit";
import {ThunkAction} from "redux-thunk";

export type PhysicsResult = {
    v: number | null,
    pt: number | null,
    lw: number | null
};

export type BaseTranslation = { title: string };
export type BaseTranslations = { [locale: string]: BaseTranslation };

type ProductTranslation = BaseTranslation & {
    description: string | null,
    model: string | null,
    assembly: string | null,
    notice: string | null
};

export type Product = {
    id: string,
    purchaseId: string,
    meshPath: string,
    meshFilename: string,
    image: string,
    translations: { [locale: string]: ProductTranslation },
    system: number,
    prices: Prices[]
};

export type Physics = {
    DN?: number,
    A?: number,
    B?: number,
    airType: number,
    calcSf: string,
    calcV: string,
    calcPt: string,
    calcLw: string
};

export type Prices = {
    DN?: number,
    D2?: number | null,
    A?: number,
    B?: number,
    price: string
};

export type ProductPrice = {
    id: string,
    price: number | null
};

export type ProductPrices = Array<ProductPrice>;

export type RooftopProduct = Product & {
    airType: number | null,
    airSpeedMin: number | null,
    airSpeedMax: number | null,
    louverMin: number | null,
    louverMax: number | null,
    louverDefault: number | null,
    minDN: number | null,
    maxDN: number | null,
    minAB: number | null,
    maxAB: number | null,
    channelProfile: boolean | null,
    physics: Physics[]
};

export type TransitionProduct = Product & {
    roofType: number,
    roofAngle: number,
    insulation: number,
    minAngle: number,
    maxAngle: number,
    model: number | null,
    assembly: number,
    purpose: number,
    insulationMm: number
};

export function instanceOfTransitionProduct(object: any): object is TransitionProduct {
    return 'assembly' in object && 'purpose' in object;
}

export function instanceOfRooftopProduct(object: any): object is RooftopProduct {
    return 'airType' in object && 'louverDefault' in object;
}

export type Rule = {
    extra: string
    transitions: string[]
    rooftops: string[]
    mandatory: boolean
};

export type Option = {
    id: number,
    translations: BaseTranslations
};

export type DataSetProducts = {
    extras: Product[],
    rooftops: RooftopProduct[],
    transitions: TransitionProduct[]
};

export type Options = {
    airTypes: Option[],
    airTypesForCalculation: Option[],
    insulations: Option[],
    roofAngles: Option[],
    roofTypes: Option[],
    systems: Option[],
    purposes: Option[],
    models: Option[],
    assemblys: Option[],
    dn: number[],
    d2: number[],
    louvers: number[]
};

export type DataSet = {
    options: Options,
    products: DataSetProducts,
    configurations: Rule[]
};

export type SelectedExtras = Array<string>;

export type UserSelection = {
    system: number,
    roofType: number,
    purpose: number | null,
    roofAngle: number,
    roofAngleValue: number,
    insulation: number,
    airType: number,
    airTypeForCalculations: number,
    rooftop: string | null,
    diameter: number,
    sizeA: number,
    sizeB: number,
    louverSelection: number,
    model: number | null,
    assembly: number | null,
    extras: SelectedExtras,
    louverSelIntModified: boolean,
    roofAngleValueWarning: boolean,
    flowRate: number | null,
    d2: number | null,
    d2Transition: number | null,
    isUserSelectionValid: boolean,
    insulationMm: number;
};

export type ProjectConfigurationFields = {
    lvPos: string,
    remark: string,
    quantity: string
}

type BaseProjectConfiguration = ProjectConfigurationFields & {
    uuid?: string,
    article: string,
    price: string,
    state: number,
    parameters: UserSelection,
    createdAt?: string | null,
    updatedAt?: string | null
};

export type ApiConfigurationProduct = {
    id: string,
    article: string,
    price: string | null,
    state: number
}

export type ApiConfigurationProducts = {
    rooftop: ApiConfigurationProduct,
    extras: Array<ApiConfigurationProduct>,
    transition: ApiConfigurationProduct,
};

export type ApiProjectConfiguration = BaseProjectConfiguration & {
    products: ApiConfigurationProducts,
};

export type ApiProject = {
    uuid?: string,
    title: string,
    customer: string,
    customerId: string,
    job: string,
    field: string,
    agent: string,
    date: string,
    projectConfigurations: { [uuid: string]: ApiProjectConfiguration },
    createdAt?: string | null,
    updatedAt?: string | null
};

// ConfigurationProducts could be null if products are removed from backend database
export type ConfigurationProduct<T> = {
    id: string,
    article: string,
    price: string | null,
    state: number,
    product: T | null
}

export type ConfigurationProducts = {
    state: number,
    rooftop: ConfigurationProduct<RooftopProduct>,
    extras: Array<ConfigurationProduct<Product>>,
    transition: ConfigurationProduct<TransitionProduct>
}

/*
export type ConfigurationProducts = {
    rooftopArticle: string,
    rooftop: RooftopProduct | null,
    extrasArticles: string[],
    extras: Array<Product | null>,
    transitionArticle: string,
    transition: TransitionProduct | null
};
*/
export type ResetPasswordData = {
    password: string,
    passwordRepeat: string
}

export type RequestPasswordResetData = {
    email: string | null,
}

export type RegistrationData = {
    company: string | null,
    email: string | null,
    gender: 'male' | 'female',
    firstName: string | null,
    lastName: string | null,
    plainPassword: string | null,
    city: string | null,
    streetNr: string | null,
    zip: string | null,
    newsletter: boolean,
    isAgreedPrivacy: boolean
};

export type ProjectConfiguration = BaseProjectConfiguration & {
    products: ConfigurationProducts,
};

export type Project = {
    uuid?: string,
    title: string,
    customer: string,
    customerId: string,
    job: string,
    field: string,
    agent: string,
    date: string,
    projectConfigurations?: { [uuid: string]: ProjectConfiguration },
    createdAt?: string | null,
    updatedAt?: string | null
};

export type FilteredOptions = Options & {
    rooftops: RooftopProduct[],
    extras: Product[]
};

export type ProductState = {
    dataSet: DataSet | null,
    userSelection: UserSelection,
    filteredOptions: FilteredOptions,
    configuration: ConfigurationProducts | null
};

export type ProjectsMap = {
    [uuid: string]: Project
};

export type ProjectState = {
    projects: ProjectsMap,
    currentProject: Project | null,
    currentProjectConfiguration: ProjectConfiguration | null
};

export type ProductViewProps = {
    locale: string,
    currentProject: Project | null,
    products: ConfigurationProducts
};

export type AuthState = {
    token: string | null,
    isAuthenticated: boolean,
    username: string | null
};

export type SidebarState = {
    visible: boolean,
    canBeHidden: boolean
};

export type AppState = {
    product: ProductState,
    project: ProjectState,
    language: LanguageState,
    auth: AuthState,
    sidebar: SidebarState
};

export type LanguageState = {
    language: string,
}

export type AppThunk<ActionTypes extends Action, ReturnType = void> = ThunkAction<
    ReturnType,
    AppState,
    unknown,
    ActionTypes
>