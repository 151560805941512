import {ProductSetDatasetAction, ProductSetParameterAction} from '../actions';
import {ProductState} from '../../types/types';
import ProductStateBuilder from '../../util/ProductStateBuilder';
import {AIRTYPE} from '../../util/constants';
import {createSlice, type PayloadAction} from "@reduxjs/toolkit";

const initialRoundSystemUserSelection = {
    system: 1,
    roofType: 1,
    purpose: 1,
    roofAngle: 1,
    roofAngleValue: 0,
    insulation: 1,
    airType: AIRTYPE.EXHAUST_AND_OUTSIDE,
    airTypeForCalculations: AIRTYPE.EXHAUST_AND_OUTSIDE,
    diameter: 100,
    sizeA: 300,
    sizeB: 300,
    rooftop: 'ABSB135',
    extras: ['RKR'],
    louverSelection: 5,
    model: null,
    assembly: null,
    louverSelIntModified: false,
    roofAngleValueWarning: false,
    flowRate: null,
    d2: null,
    d2Transition: null,
    isUserSelectionValid: true,
    insulationMm: 0,
};

const initialRectangularSystemUserSelection = {
    system: 2,
    roofType: 1,
    purpose: 1,
    roofAngle: 1,
    roofAngleValue: 0,
    insulation: 1,
    airType: AIRTYPE.EXHAUST_AND_OUTSIDE,
    airTypeForCalculations: AIRTYPE.EXHAUST_AND_OUTSIDE,
    diameter: 100,
    sizeA: 300,
    sizeB: 300,
    rooftop: 'ASBEF',
    extras: [],
    louverSelection: 5,
    model: null,
    assembly: null,
    louverSelIntModified: false,
    roofAngleValueWarning: false,
    flowRate: null,
    d2: null,
    d2Transition: null,
    isUserSelectionValid: true,
    insulationMm: 0,
};

const initialUserSelection = {
    1: initialRoundSystemUserSelection,
    2: initialRectangularSystemUserSelection
}

const initialState: ProductState = {
    dataSet: null,
    userSelection: initialRoundSystemUserSelection,
    filteredOptions: {
        airTypes: [],
        airTypesForCalculation: [],
        insulations: [],
        roofAngles: [],
        roofTypes: [],
        systems: [],
        purposes: [],
        models: [],
        assemblys: [],
        dn: [],
        louvers: [],
        rooftops: [],
        extras: [],
        d2: []
    },
    configuration: null
};

function resolveActionValue(action: ProductSetParameterAction) {
    switch (action.parameter) {
        case 'rooftop':
        case 'extras':
            return action.value;

        default:
            if (typeof action.value === 'string') {
                return Number.parseInt(action.value);
            }

            return action.value;
    }
}

const productSlide = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setDataset(state, action: PayloadAction<ProductSetDatasetAction>) {
            let userSelection = { ...state.userSelection }
            return new ProductStateBuilder(action.payload.dataSet).build(userSelection, userSelection);
        },
        setParameter(state, action: PayloadAction<ProductSetParameterAction>) {
            if (!state.dataSet) {
                return state;
            }

            let dataSet = {...state.dataSet}

            let newUserSelection = action.payload.parameter === 'system'
                // @ts-ignore
                ? initialUserSelection['' + (action.payload.value || 1)]
                : {
                    ...state.userSelection,
                    [action.payload.parameter]: resolveActionValue(action.payload)
                }
            newUserSelection = {...newUserSelection}

            let oldUserSelection = { ...state.userSelection }

            state = new ProductStateBuilder(dataSet).build(newUserSelection, oldUserSelection);
            //console.log(state);
            return state;
        },
    },
})

export const { setDataset, setParameter } = productSlide.actions
export default productSlide.reducer