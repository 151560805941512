import * as React from 'react';
import * as Action from '../../../store/actions';
import { Button, Divider, Header, Icon } from 'semantic-ui-react';
import {Project} from "../../../types/types";
import DeleteButton from "../../../components/common/DeleteButton";
import ProjectConfigurationList from "./ProjectConfigurationList";
import ProjectDetails from "./ProjectDetails";
import {SubmitHandler} from "react-hook-form";
import {CreateProjectInput} from "./CreateProjectForm";
import {useTranslation} from "react-i18next";



type Props = {
    locale: string,
    project: Project,
    token: string | null,
    isSmallScreen: boolean,
    projectSelect: typeof Action.projectSelect,
    projectUpdate: typeof Action.projectUpdate,
    projectRemove: typeof Action.projectRemove,
    projectDeleteConfiguration: typeof Action.projectDeleteConfiguration
};

export default function ProjectDetailView(props: Props) {

  const { t } = useTranslation();

  const projectIsEmpty: boolean = typeof props.project.projectConfigurations === 'undefined'
                                  || Object.keys(props.project.projectConfigurations).length < 1;

  const createPdfUrl = (withPrices: boolean) => {
    return process.env.REACT_APP_API_BASE_URL
        + '/projects/' + props.project.uuid
        + '/pdf?locale=' + props.locale
        + '&prices=' + (withPrices ? 1 : 0)
        + '&bearer=' + props.token;
  }

  return (
      <div>
          <DeleteButton
              floated="right"
              size="mini"
              content={props.isSmallScreen
                  ? t('mobileText.projects.component.projectDetailView.projectDeleteButton')
                  : t('projects.component.projectDetailView.projectDeleteButton')
              }
              deleteCallback={() => props.projectRemove(props.locale, props.project)}
          />

          <Button
              floated="right"
              color="blue"
              size="mini"
              icon="chevron left"
              content={props.isSmallScreen
                  ? t('mobileText.projects.component.projectDetailView.changeProjectButton')
                  : t('projects.component.projectDetailView.changeProjectButton')
              }
              onClick={() => props.projectSelect(null)}
          />

          <Divider clearing={true} hidden={true}/>

          <div>

              <ProjectDetails
                  locale={props.locale}
                  project={props.project}
                  projectUpdate={props.projectUpdate}
              />

              <Divider clearing={true} hidden={true}/>

              <div>
                  <Header
                      content={t('projects.component.projectDetailView.pdfExport.header')}
                      textAlign="right"
                  />

                  <Button
                      disabled={projectIsEmpty}
                      floated="right"
                      color="green"
                      target="_blank"
                      size="mini"
                      href={createPdfUrl(true)}
                  >
                      <Icon name="file pdf outline"/>
                      {t('projects.component.projectDetailView.pdfExport.withPricesButton')}
                  </Button>
                  <Button
                      disabled={projectIsEmpty}
                      floated="right"
                      color="green"
                      target="_blank"
                      size="mini"
                      href={createPdfUrl(false)}
                  >
                      <Icon name="file pdf outline"/>
                      {t('projects.component.projectDetailView.pdfExport.withoutPricesButton')}
                  </Button>
              </div>
          </div>

          <Divider clearing={true} hidden={true}/>

          <Header content={t('projects.component.projectDetailView.configurations.valid.header')}/>
          <ProjectConfigurationList
              locale={props.locale}
              project={props.project}
              projectDeleteConfiguration={props.projectDeleteConfiguration}
          />
      </div>
  );
}
