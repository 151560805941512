import * as React from 'react';
import { Card, Image } from 'semantic-ui-react';
import {PRODUCT_STATE} from "../../util/constants";
import images from "../../util/Images";
import {useTranslation} from "react-i18next";


const InvalidProductItem = ({article, state}: { article: string | null, state: number }) => {

    const { t } = useTranslation();

    let imgSrc = ``;
    let text = ``;

    switch (state) {
        case PRODUCT_STATE.DELETED:
            imgSrc = images.artikelGeloescht;
            text = t('projects.component.projectDetailView.configurations.invalid.product.deleted');
            break;

        case PRODUCT_STATE.OFFLINE:
            imgSrc = images.artikelOffline;
            text = t('projects.component.projectDetailView.configurations.invalid.product.notAvailable');
            break;

        default:
            return null;
    }

    return (
        <Card>
            <Image src={imgSrc} />
            <Card.Content>
                <Card.Header>
                    {article}
                </Card.Header>
                <Card.Description>
                    {text}
                </Card.Description>
            </Card.Content>
        </Card>
    );
};

export default InvalidProductItem;