import * as React from 'react';
import { Form, Grid } from 'semantic-ui-react';
import FormInput from "../../../components/form/FormInput";
import {date, object, preprocess, string, TypeOf, z} from 'zod';
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useState} from "react";

import {Project} from "../../../types/types";
import {useTranslation} from "react-i18next";
import FormDateInput from "../../../components/form/FormDateInput";
import I18n from "../../../i18n/i18n";



const createProjectFormSchema = object({
    title: string().min(1, { message: 'user.formValidatorMessages.required'}),
    job: string().optional(),
    field: string().optional(),
    agent: string().optional(),
    date: preprocess(
        (arg) => (arg === '' ? null : arg),
        z.coerce.date().nullish(),
    ),
    customer: string().optional(),
    customerId: string().optional(),
});


export type CreateProjectInput = TypeOf<typeof createProjectFormSchema>;

interface FormProps {
    onSubmit: (values: Project) => void,
    //onSubmit: Function,
}

function CreateProjectForm(props: FormProps) {

    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();

    const methods = useForm<CreateProjectInput>({
        resolver: zodResolver(createProjectFormSchema),
    });

    const {
        handleSubmit,
        formState: { isSubmitSuccessful },
    } = methods;

    const onSubmitHandler: SubmitHandler<CreateProjectInput> = async (values) => {

        setLoading(true);

        // @ts-ignore
        props.onSubmit(values);
    };

    const rtLbl = 'projects.component.createProjectModal.label.';

    return (
        <FormProvider {...methods}>

            <Form id="createProject" onSubmit={handleSubmit(onSubmitHandler)} loading={loading}>
                <Grid stackable={true}>
                    <Grid.Row columns="equal">
                        <Grid.Column>
                            <FormInput mandatory={true} maxLength={250} name={'title'} type={'text'} label={t(rtLbl + 'title')}/>
                            <FormInput maxLength={250} name={'job'} type={'text'} label={t(rtLbl + 'job')}/>
                            <FormInput maxLength={250} name={'field'} type={'text'} label={t(rtLbl + 'field')}/>
                            <FormInput maxLength={124} name={'agent'} type={'text'} label={t(rtLbl + 'agent')}/>
                            <FormDateInput name={'date'} label={I18n.t(rtLbl + 'date')}/>
                        </Grid.Column>
                        <Grid.Column>
                            <FormInput maxLength={124} name={'customer'} type={'text'} label={t(rtLbl + 'customer')}/>
                            <FormInput maxLength={124} name={'customerId'} type={'text'} label={t(rtLbl + 'customerId')}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>

        </FormProvider>
    );

}

export default CreateProjectForm
