import * as React from 'react';
import { Option } from "../../../types/types";
import { Form } from 'semantic-ui-react'

interface Props {
    locale: string,
    label: string,
    options: Option[],
    value: number | string | null,
    updateValue: (value: string) => void
}

interface State {
}

class AirTypeSelectionForCalculation extends React.PureComponent<Props, State> {
    public render() {
        return (
            <Form inverted={true}>
                <Form.Field>
                    <label style={{display: 'inline', marginLeft: '16px', fontSize: '1em'}}>{this.props.label}</label>
                    <Form.Group className="float-right margin-top-2px" inline={true}>
                        {this.props.options.map((option: Option) => {
                            return (
                                    <Form.Radio
                                        key={option.id}
                                        label={option.translations[this.props.locale].title}
                                        value={option.id}
                                        checked={this.props.value === option.id}
                                        onChange={() => this.props.updateValue(option.id.toString())}
                                    />
                                );
                            }
                        )}
                    </Form.Group>
                </Form.Field>
            </Form>
        )
    };
}

export default AirTypeSelectionForCalculation;