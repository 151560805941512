import React, {useState} from 'react';
import {bindActionCreators} from "@reduxjs/toolkit";
import {Dispatch} from "redux";
import {SelectProjectAction} from "../store/actions";
import * as Action from '../store/actions';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Button, Form, Icon, Message} from "semantic-ui-react";
import FormInput from "../components/form/FormInput";
import {boolean, object, string, TypeOf, z} from "zod";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import FormCheckbox from "../components/form/FormCheckbox";
import FormDropdown from "../components/form/FormDropdown";




const registrationFormSchema = object({
  email: string().min(1, { message: 'user.formValidatorMessages.required'}).email({message: 'user.formValidatorMessages.emailNotValid'}),
  plainPassword: string().min(6, { message: 'user.formValidatorMessages.min6'}).max(4096, {message: 'user.formValidatorMessages.max4096'}),
  gender: z.enum(["male", "female"]),
  firstName: string().min(1, { message: 'user.formValidatorMessages.required'}).max(64, { message: 'user.formValidatorMessages.max64'}),
  lastName: string().min(1, { message: 'user.formValidatorMessages.required'}).max(128, { message: 'user.formValidatorMessages.max128'}),
  company: string().min(1, { message: 'user.formValidatorMessages.required'}).max(255, { message: 'user.formValidatorMessages.max255'}),
  streetNr: string().min(1, { message: 'user.formValidatorMessages.required'}).max(255, { message: 'user.formValidatorMessages.max255'}),
  zip: string().min(1, { message: 'user.formValidatorMessages.required'}).max(16, { message: 'user.formValidatorMessages.max16'}),
  city: string().min(1, { message: 'user.formValidatorMessages.required'}).max(128, { message: 'user.formValidatorMessages.max128'}),
  newsletter: boolean(),
  isAgreedPrivacy: boolean().superRefine((val, ctx) => {
    if (!val) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "user.formValidatorMessages.privacyCheckbox",
      });
    }
  })
});




export type RegistrationInput = TypeOf<typeof registrationFormSchema>;

type DispatchProps = {
  registrationCreate: typeof Action.registrationCreate
};

function RegistrationForm(props: DispatchProps) {

  const { t } = useTranslation();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const methods = useForm<RegistrationInput>({
    defaultValues: {
      gender: 'female',
      isAgreedPrivacy: false,
      newsletter: false,
    },
    resolver: zodResolver(registrationFormSchema),
  });

  const {
    handleSubmit,
    formState: { isSubmitSuccessful, isValid },
    setError,
  } = methods;

  const onSubmitHandler: SubmitHandler<RegistrationInput> = async (values) => {

    try {
      // Show Success message
      let [response] = await Promise.all([props.registrationCreate(values)])
      setIsSubmitted(true);
    } catch (e: unknown) {
      // Set errors
      // @ts-ignore
      let errorObject = e.errorObject;
      for (const [key, value] of Object.entries(errorObject)) {
        // @ts-ignore
        if (value && "errors" in value) {
          // @ts-ignore
          let errorMessage = value.errors[0];
          // @ts-ignore
          setError(key, {message: errorMessage});
        }
      }

    }

  };

  const rtLbl = 'user.component.registration.label.';
  const rtLbp = 'user.component.registration.privacy.';
  const options = [
    {
      key: 'gendFemale',
      value: 'female',
      text: t('user.component.registration.gender.female')
    },
    {
      key: 'gendMale',
      value: 'male',
      text: t('user.component.registration.gender.male')
    }
  ];

  return (
      <FormProvider {...methods}>

        {isSubmitted ?
          <Message positive={true}>
            <Message.Header>{t('user.component.registration.success.headline')}</Message.Header>
            <p>{t('user.component.registration.success.copytext')}</p>
          </Message>
          :

          <Form onSubmit={handleSubmit(onSubmitHandler)}>
            <FormInput
                name={'email'}
                type={'email'}
                label={t(rtLbl + 'email')}
                autoComplete={'username'}
            />
            <FormInput
                name={'plainPassword'}
                type={'password'}
                label={t(rtLbl + 'plainPassword')}
                autoComplete={'new-password'}
            />

            <Form.Group>
             <FormDropdown
                  name="gender"
                  label={t(rtLbl + 'gender')}
                  options={options}
                  width={3}
              />
              <FormInput
                  name={'firstName'}
                  type={'text'}
                  label={t(rtLbl + 'firstName')}
                  width={6}
              />
              <FormInput
                  name={'lastName'}
                  type={'text'}
                  label={t(rtLbl + 'lastName')}
                  width={7}
              />
            </Form.Group>

            <FormInput
                name={'company'}
                type={'text'}
                label={t(rtLbl + 'company')}
            />
            <FormInput
                name={'streetNr'}
                type={'text'}
                label={t(rtLbl + 'streetNr')}
            />

            <Form.Group widths="equal">
              <FormInput
                  name={'zip'}
                  type={'text'}
                  label={t(rtLbl + 'zip')}
              />
              <FormInput
                  name={'city'}
                  type={'text'}
                  label={t(rtLbl + 'city')}
              />
            </Form.Group>

            <FormCheckbox name={'newsletter'} label={t(rtLbl + 'newsletter')}/>
            <FormCheckbox
                name={'isAgreedPrivacy'}
                label={t(rtLbp + 'label')}
                link={{
                  url: t(rtLbp + 'link.url'),
                  text: t(rtLbp + 'link.text'),
                }}/>

            <Button type="submit" disabled={!isValid}>
              <Icon name="sign in"/> {t('user.component.registration.registerButton')}
            </Button>
          </Form>
        }
      </FormProvider>
  );
}




function mapDispatchToProps(dispatch: Dispatch<SelectProjectAction>): DispatchProps {
  return bindActionCreators(
      {
        registrationCreate: Action.registrationCreate
      },
      dispatch
  );
}

export default connect(null, mapDispatchToProps)(RegistrationForm);
