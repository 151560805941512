import React from 'react';
import {Icon, Menu, Sidebar} from "semantic-ui-react";
import * as Action from '../store/actions';
import {connect} from "react-redux";
import {bindActionCreators} from "@reduxjs/toolkit";
import {
  AppState,
  ConfigurationProducts,
  DataSet,
  FilteredOptions,
  Project,
  ProjectsMap,
  SidebarState,
  UserSelection
} from "../types/types";
import {Dispatch} from "redux";
import FilterForm from "../modules/configurator/components/FilterForm";
import ProductView from "../modules/configurator/components/ProductView";

type StateProps = {
  sidebarState: SidebarState,
  locale: string,
  dataSet: DataSet | null,
  filteredOptions: FilteredOptions,
  currentProject: Project | null,
  products: ConfigurationProducts | null,
  userSelection: UserSelection,
  projects: ProjectsMap,
};

type DispatchProps = {
  setParameter: typeof Action.setParameter,
  toggleSidebar: typeof Action.toggleSidebar
};


function ConfiguratorPage(props: StateProps & DispatchProps) {

  return (
      <div className="configurator-page">
        <Sidebar
            className="filter-form-sidebar"
            visible={props.sidebarState.visible}
            width="wide"
            animation="overlay"
            as={Menu}
            inverted={true}
            vertical={true}
        >
          {props.sidebarState.canBeHidden && <Menu text={true} compact={true}>
              <Menu.Item>
                  <Icon
                      size="big"
                      name="sidebar"
                      inverted={true}
                      style={{margin: 0}}
                      onClick={() => props.toggleSidebar()}
                  />
              </Menu.Item>
          </Menu>}
          {props.dataSet &&
              <FilterForm
                  configurations={props.dataSet.configurations}
                  userSelection={props.userSelection}
                  products={props.products}
                  locale={props.locale}
                  setParameter={props.setParameter}
                  filteredOptions={props.filteredOptions}
              />}
        </Sidebar>

        {<ProductView
            locale={props.locale}
            currentProject={props.currentProject}
            products={props.products}
            isSmallScreen={props.sidebarState.canBeHidden}
            isUserSelectionValid={props.userSelection.isUserSelectionValid}
        />}

      </div>
  );
}


function mapStateToProps(state: AppState): StateProps {
  return {
    sidebarState: state.sidebar,
    dataSet: state.product.dataSet,
    filteredOptions: state.product.filteredOptions,
    products: state.product.configuration,
    userSelection: state.product.userSelection,
    currentProject: state.project.currentProject,
    projects: state.project.projects,
    locale: state.language.language,
  };
}

function mapDispatchToProps(dispatch: Dispatch<Action.ProductSetParameterAction>): DispatchProps {
  return bindActionCreators(
      {
        setParameter: Action.setParameter,
        toggleSidebar: Action.toggleSidebar
      },
      dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfiguratorPage);
