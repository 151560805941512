import { Physics, PhysicsResult, UserSelection } from '../../../types/types';
import * as mathjs from 'mathjs';

export default abstract class AbstractCalculator {
    protected userSelection: UserSelection;
    protected physics: Physics[];

    private currentPhysics: Physics | null;

    public constructor(userSelection: UserSelection, physics: Physics[]) {
        this.userSelection = userSelection;
        this.physics = physics;
        this.currentPhysics = this.getPhysics();
    }

    public calculate(V: number | null) {

        if (this.currentPhysics !== null && V !== null && Number.isFinite(V)) {

            let parser = mathjs.parser();
            parser.set('V', V);

            parser.evaluate(this.currentPhysics.calcSf);
            parser.evaluate(this.currentPhysics.calcV);
            parser.evaluate(this.currentPhysics.calcPt);
            parser.evaluate(this.currentPhysics.calcLw);

            return this.createResult(parser);
        }

        return {
            v: null,
            pt: null,
            lw: null
        };
    }

    public abstract getErrors(): string[];

    protected abstract getPhysics(): Physics | null;

    protected abstract createResult(parser: mathjs.Parser): PhysicsResult;
}