import  * as React from 'react'
import * as Action from '../../../store/actions'
import { Button, Modal } from 'semantic-ui-react'
import {AppState, ConfigurationProducts, Project, ProjectConfigurationFields, UserSelection} from '../../../types/types'
//import AddConfigurationForm, { FormData } from './AddConfigurationForm'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {compose, Dispatch} from '@reduxjs/toolkit'
import I18n from "../../../i18n/i18n";
import AddConfigurationForm from "./AddConfigurationForm";
import {withTranslation} from "react-i18next";

type DispatchFromProps = {
    projectAddConfiguration: typeof Action.projectAddConfiguration
};

type StateFromProps = {
    userSelection: UserSelection
};

type AddButton = {
    [key: string]: string
};

type PassedProps = {
    isSmallScreen: boolean,
    style: AddButton,
    currentProject: Project | null,
    products: ConfigurationProducts,
    locale: string,
    isUserSelectionValid: boolean
};

type State = {
    open: boolean
};

class AddConfigurationModal extends React.Component<PassedProps & DispatchFromProps & StateFromProps, State> {
    public state: State = {
        open: false
    }

    public render() {
        return (
            <div style={{position: 'relative'}}>
                <Button
                    className="pointer-events-enabled"
                    icon="arrow up"
                    labelPosition="right"
                    floated="right"
                    content={this.props.isSmallScreen
                        ? (I18n.t('mobileText.projects.component.addConfigurationModal.modalOpenButton'))
                        : (I18n.t('projects.component.addConfigurationModal.modalOpenButton'))}
                    size="mini"
                    color="blue"
                    style={this.props.style}
                    disabled={this.props.currentProject === null || !this.props.isUserSelectionValid}
                    onClick={() => this.setState({open: true})}
                />
                <Modal open={this.state.open}>
                    <Modal.Header>{I18n.t('projects.component.addConfigurationModal.header')}</Modal.Header>
                    <Modal.Content>
                      <AddConfigurationForm
                            onSubmit={this.createConfiguration}
                            onSubmitSuccess={() => this.setState({open: false})}
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            color="red"
                            icon="remove"
                            labelPosition="right"
                            content={I18n.t('projects.component.addConfigurationModal.cancelButton')}
                            onClick={() => this.setState({open: false})}
                        />
                        <Button
                            type="submit"
                            form="addConfiguration"
                            positive={true}
                            labelPosition="right"
                            icon="checkmark"
                            content={I18n.t('projects.component.addConfigurationModal.createButton')}
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }

    private createConfiguration = (values: ProjectConfigurationFields) => {
        return this.props.projectAddConfiguration(
            this.props.locale,
            values,
            this.props.userSelection,
            this.props.products
        )
    }
}

function mapDispatchToProps(dispatch: Dispatch<Action.ProductSetParameterAction>): DispatchFromProps {
    return bindActionCreators(
        {
            projectAddConfiguration: Action.projectAddConfiguration
        },
        dispatch
    )
}

function mapStateToProps(state: AppState): StateFromProps {
    return {
        userSelection: state.product.userSelection
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AddConfigurationModal));

/*
export default connect<StateFromProps, DispatchFromProps, PassedProps>(
    // @ts-ignore
    mapStateToProps,
    mapDispatchToProps
)(AddConfigurationModal)*/
