import * as React from 'react';
import { Project } from '../../../types/types';
import { Card } from 'semantic-ui-react';
import * as Action from '../../../store/actions';
import i18n from "../../../i18n/i18n";
import {withTranslation} from "react-i18next";


type Props = {
    project: Project,
    selectProject: typeof Action.projectSelect,
    projectRemove: typeof Action.projectRemove
};

class ProjectCard extends React.Component<Props> {
    public render() {
      let {project} = this.props;

      // @ts-ignore
      let count = Object.values(project.projectConfigurations).length;

      return (
            <Card onClick={() => this.props.selectProject(project)}>
                <Card.Content header={project.title} />
                <Card.Content extra={true}>
                  {i18n.t('projects.component.projectCard.configuration', {
                    'count': count,
                  })}
                </Card.Content>
            </Card>
        );
    }
}

export default withTranslation()(ProjectCard)
