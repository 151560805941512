import {
  ConfigurationProducts,
  Prices,
  Product,
  ProductPrices,
  RooftopProduct,
  Rule,
  TransitionProduct,
  UserSelection
} from '../../../types/types'
import { SYSTEM_RECTANGULAR, SYSTEM_ROUND } from '../../../util/constants'
import * as mathjs from 'mathjs'
import getStandardLouverCnt from '../../../util/getStandardLouverCnt'
import { PRICE_CALCULATION_ERROR } from '../../../store/actions'

type RetrivalParams = {
  meter: number,
  type: string,
  d2?: number | null
};
type ProductPriceFormula = { id: string, priceFormula: string };
type ProductPriceFormulas = { id: string, priceFormulas: Array<Prices> };
type ConfigurationPriceFormulas = Array<ProductPriceFormula>;
type ProdArrTypes = Product | RooftopProduct | TransitionProduct | null;

export function getPrices (
  products: ConfigurationProducts,
  parameters: UserSelection,
  configurations: Rule[]
): ProductPrices {

  if (products.rooftop.product && products.transition.product && products.extras) {
    const { louverMin, louverMax, id } = products.rooftop.product

    const { sizeA, sizeB, louverSelection } = parameters

    const productArray: Array<ProdArrTypes> = [
      products.rooftop.product,
      products.transition.product,
      ...products.extras.map((extra) => extra.product)
    ]

    const isAStandardLouverCnt: boolean = isStandardLouverCnt(louverMin, louverMax, sizeA, sizeB, louverSelection)

    const retrivalParameters: RetrivalParams = getParameters(parameters)

    const configurationPriceFormulars: ConfigurationPriceFormulas = retrieveCalculationFormulas(
      productArray,
      retrivalParameters
    )

    return configurationPriceFormulars.map((formula: ProductPriceFormula) => {
      try {

        if (
          productIsExtra(formula.id, configurations)
          && extraIsMandatory(formula.id, configurations, products.transition.id, products.rooftop.id)
        ) {
          return {
            id: formula.id,
            price: -1
          }
        }

        let productIsNotRooftop: boolean = formula.id !== id

        let price = productIsNotRooftop
          ? calculatePrice(parameters.roofAngleValue)(formula.priceFormula)
          : isAStandardLouverCnt
            ? calculatePrice(parameters.roofAngleValue)(formula.priceFormula)
            : null

        return {
          id: formula.id,
          price: price
        }
      } catch (Error) {
        // da ist der Wurm drin;
      }
      return {
        id: formula.id,
        price: null
      }
    })
  }
  let error = Error('No price calculation possible, invalid project configuration')
  error.name = PRICE_CALCULATION_ERROR
  throw error
}

export const sumPrices = (prices: ProductPrices, quantity: string) => {
  try {
    const price = prices.reduce(
      (acc, item) => {
        if (item.price === null) {
          throw new Error()
        }
        if (item.price < 0) {
          // could happen for mandatory extras -> do not add, is included already
          return acc
        }
        return acc + item.price
      },
      0
     )
    return '€ ' + (price * parseInt(quantity, 10)).toFixed(2).replace('.', ',')
  } catch (err) {
    //
  }

  return null
}

export function isStandardLouverCnt (
  louverMin: number | null,
  louverMax: number | null,
  sizeA: number | null,
  sizeB: number | null,
  louverSelection: number
): boolean {
  if (louverMin !== null && louverMax !== null && sizeA !== null && sizeB !== null) {
    if (louverMin > louverMax) {
      throw new Error('Invalid Data, louverMin > louverMax')
    }

    const louverNumberIsAdjustable = louverMin < louverMax

    if (louverNumberIsAdjustable) {
      try {
        return louverSelection === getStandardLouverCnt(sizeA, sizeB)

      } catch (error) {
        return false
      }
    }

    return true
  }

  return false
}

export function getParameters (parameters: UserSelection): RetrivalParams {
  switch (parameters.system) {
  case SYSTEM_ROUND:
    return { meter: parameters.diameter, type: 'DN', d2: parameters.d2 }

  case SYSTEM_RECTANGULAR:
    return { meter: roundUp100(average(parameters.sizeA, parameters.sizeB)), type: 'A' }

  default:
    throw new Error(`SYSTEM_type: ${parameters.system} - is unknown or not specified`)
  }
}

export function retrieveCalculationFormulas (
  productArray: ProdArrTypes[],
  retrivalParameters: RetrivalParams
): ConfigurationPriceFormulas {
  const { type, meter } = retrivalParameters

  const configurationFormulasArray: Array<ProductPriceFormulas> = productArray.map((item) => {
    if (item) {
      if (item.prices.length > 0) {
        return {
          id: item.id,
          priceFormulas: item.prices
        }
      }

      return {
        id: item.id,
        priceFormulas: []
      }
    }
    throw new Error('No price calculation possible, invalid project configuration')
  })

  return configurationFormulasArray.map(
    (product: ProductPriceFormulas) => {

      const filteredFormulas = type === 'DN'
        ? product.priceFormulas.filter(price => meter !== null && meter === price.DN)
          // @ts-ignore
        : product.priceFormulas.filter(price => meter !== null && meter <= (price[type] || 0))

      if (filteredFormulas.length === 0) {
        return {
          id: product.id,
          priceFormula: ''
        }
      }

      return (
        type === 'DN'
          // there could be multiple prices for DN with different D2 values
          ? filterPricesForRoundSystem(filteredFormulas, retrivalParameters, product.id)
          : {
            id: product.id,
            priceFormula: filteredFormulas[0].price
          }
      )
    }
  )
}

function filterPricesForRoundSystem (
  prices: Array<Prices>,
  retrivalParameters: RetrivalParams,
  productId: string
): { id: string, priceFormula: string } {
  return (
    prices.length === 1
      ? {
        id: productId,
        priceFormula: prices[0].price
      }
      : {
        id: productId,
        priceFormula: prices.filter(
          (price: Prices) => price.D2 === retrivalParameters.d2
        ).map((priceObj: Prices) => priceObj.price)[0]
      }
  )
}

export const calculatePrice = (angle: number) => (priceFormula: string): number => {
  try {
    let parser = mathjs.parser()

    parser.evaluate(priceFormula)
    parser.set('angle', angle)

    return parseFloat(parser.evaluate('calcPrice(angle)'))
  } catch (error) {
    // @ts-ignore
    throw new Error(error)
  }
}

export function average (...args: number[]): number {
  return args.reduce((acc, num) => acc + num) / args.length
}

export function roundUp100 (value: number): number {
  return roundUp(value, 100)
}

export function roundUp (value: number, increment: number): number {
  return Math.ceil(value / increment) * increment
}

function productIsExtra (id: string, configurations: Rule[]): boolean {
  return configurations.some(config => config.extra === id)
}

function extraIsMandatory (extraId: string, configurations: Rule[], transitionId: string, rooftopId: string): boolean {
  let filtered = configurations.filter(item => {
    return (item.transitions.length === 0 || item.transitions.includes(transitionId)) &&
      (item.rooftops.length === 0 || item.rooftops.includes(rooftopId))
  })

  const config = filtered.find(cfg => cfg.extra === extraId)

  if (config) {
    return config.mandatory
  }

  return false
}
