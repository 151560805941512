import { ProjectState } from '../../types/types';
import {createSlice, type PayloadAction} from "@reduxjs/toolkit";
import {
    LoadProjectsAction,
    ProductSetDatasetAction,
    SelectProjectAction
} from "../actions";

let initialState: ProjectState = {
    projects: {},
    currentProject: null,
    currentProjectConfiguration: null
};

const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        logout(state, action: PayloadAction<ProductSetDatasetAction>) {
            return {
                ...initialState,
            };
        },
        loadProjects(state, action: PayloadAction<LoadProjectsAction>): ProjectState {

            let currentProject = state.currentProject;
            if (currentProject) {

                if (currentProject.uuid && currentProject.uuid in action.payload.projects) {
                    // @ts-ignore
                    currentProject = action.payload.projects[currentProject.uuid];
                }
            }

            return {
                ...state,
                // @ts-ignore
                projects: action.payload.projects,
                currentProject: currentProject
            };
        },
        selectProject(state, action: PayloadAction<SelectProjectAction>): ProjectState {

            return {
                ...state,
                currentProject: action.payload.project
            };
        }

    },
})

export const { logout, loadProjects } = projectSlice.actions
export default projectSlice.reducer