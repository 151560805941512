// Do not edit.
import { ShaderStore } from "../../Engines/shaderStore";

const name = "depthPrePass";
const shader = `#ifdef DEPTHPREPASS
gl_FragColor= vec4f(0.,0.,0.,1.0);return;
#endif
`;
// Sideeffect
ShaderStore.IncludesShadersStoreWGSL[name] = shader;
/** @internal */
export const depthPrePass = { name, shader };
