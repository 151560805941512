
import { STANDARD_LOUVER_VALUES } from './constants';

export default function (sizeA: number, sizeB: number): number {
    const louverStandards = STANDARD_LOUVER_VALUES;
    const meanLength: number | null = ( sizeA + sizeB ) / 2;
    const comparisonMeter = Math.ceil( meanLength / 100 ) * 100;

    /*if (!Object.keys(louverStandards).includes(comparisonMeter.toString())) {
        throw new Error(`Can't find louverStandard in STANDARD_LOUVER_VALUES for meanlength of: ${comparisonMeter}`);
    }*/
    // @ts-ignore
    return louverStandards[comparisonMeter];
}