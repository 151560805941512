import React from 'react';
import {Header, Segment} from "semantic-ui-react";
import {AuthState} from "../types/types";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import RegistrationForm from "../forms/RegistrationForm";

type RegistrationPageProps = {
  authState: AuthState,
};

function RegistrationPage(props: RegistrationPageProps) {

  const navigate = useNavigate();
  const { t } = useTranslation();

  if (props.authState.isAuthenticated) {
    navigate('/')
  }

  return (
      <div className="registration on-overflow-scroll">
        <Header as="h1">
          {t('registrationPage.headline')}
        </Header>

        <Segment className="on-overflow-scroll">
          <RegistrationForm/>
        </Segment>
      </div>
  );
}


export default (RegistrationPage);
