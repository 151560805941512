
import {LanguageState} from '../../types/types';
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {ChangeLanguageAction} from "../actions";

let initialState: LanguageState = {
    language: 'de',
}

const languageSlide = createSlice({
    name: 'language',
    initialState,
    reducers: {
        changeLanguage(state, action: PayloadAction<ChangeLanguageAction>) {
            return {
                ...state,
                language: action.payload.language,
            };

        },

    },
})

export const { changeLanguage } = languageSlide.actions
export default languageSlide.reducer