export const SYSTEM_ROUND = 1;
export const SYSTEM_RECTANGULAR = 2;

export const AIRTYPE = {
    EXHAUST: 1,
    OUTSIDE: 2,
    EXHAUST_AND_OUTSIDE: 3
};

export const NONINSULATED = 1;

// Exceptional rooftops in relation to available D2s
export const EXCEPTIONAL_ROOFTOPS = [
    'FLH',
    'FFH',
    'LHR'
];

// { side_length: standardLouverNumber }, according to 6HID8336PSFIJ3SKLBSNTFDSNS.pdf
export const STANDARD_LOUVER_VALUES = {
    300: 5,
    400: 6,
    500: 7,
    600: 7,
    700: 8,
    800: 9,
    900: 10,
    1000: 11,
    1100: 12,
    1200: 12,
    1300: 13,
    1400: 14,
    1500: 15
};

export const CONFIGURATION_STATE = {
    VALIDE: 1,
    INVALIDE: 2,
};

export const PRODUCT_STATE = {
    AVAILABLE: 1,
    DELETED: 2,
    OFFLINE: 3,
};
