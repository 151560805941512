import * as React from 'react';
import { Form } from 'semantic-ui-react';
import FormInput from "../../../components/form/FormInput";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {CreateProjectInput} from "./CreateProjectForm";
import {coerce, date, number, object, string, TypeOf} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";

const AddConfigurationFormSchema = object({
  lvPos: string().optional(),
  remark: string().optional(),
  quantity: coerce.number({
    required_error: "projects.component.addConfigurationModal.validation.quantity.required",
    invalid_type_error: "projects.component.addConfigurationModal.validation.quantity.number",
  }).positive('projects.component.addConfigurationModal.validation.quantity.required')
      .min(1, 'Min...')
      .max(1000, 'projects.component.addConfigurationModal.validation.quantity.max'),
});

export type AddConfigurationInput = TypeOf<typeof AddConfigurationFormSchema>;

type Props = {
  onSubmit: Function,
  onSubmitSuccess: Function,
};

function AddConfigurationForm(props: Props) {

  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  let rtLbl = 'projects.component.addConfigurationModal.label.';

  // Set default value 1 for quantity
  const methods = useForm<AddConfigurationInput>({
    defaultValues: {
      quantity: 1,
    },
    resolver: zodResolver(AddConfigurationFormSchema),
  });

  const {
    handleSubmit,
    formState: { isSubmitSuccessful },
    reset,
    getValues,
  } = methods;


  const onSubmitHandler: SubmitHandler<AddConfigurationInput> = async (values) => {
    setLoading(true);

    props.onSubmit(values).then((data: any) => {
      props.onSubmitSuccess();
      setLoading(false);
      reset(getValues());
    });
  };

  return (
      <FormProvider {...methods}>
        <Form id="addConfiguration" onSubmit={handleSubmit(onSubmitHandler)} loading={loading}>

          <FormInput mandatory={true} maxLength={10} name={'lvPos'} type={'text'} label={t(rtLbl + 'lvPos')}/>
          <FormInput maxLength={250} name={'remark'} type={'text'} label={t(rtLbl + 'remark')}/>
          <FormInput maxLength={10} name={'quantity'} type={'text'} label={t(rtLbl + 'quantity')}/>

        </Form>
      </FormProvider>
  )

}

export default AddConfigurationForm;
