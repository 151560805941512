import * as React from 'react';
import * as Action from '../../../store/actions';
import { Card, Divider, Message } from 'semantic-ui-react';
import { Project } from '../../../types/types';
import CreateProjectModal from "./CreateProjectModal";
import ProjectCard from "./ProjectCard";
import {withTranslation} from "react-i18next";
import i18n from "i18next";

type Props = {
    locale: string,
    projects: Project[],
    project: Project | null,
    token: string | null,
    loadProjects: typeof Action.loadProjects,
    projectSelect: typeof Action.projectSelect,
    projectRemove: typeof Action.projectRemove,
    projectCreate: typeof Action.projectCreate
};

class ProjectList extends React.Component<Props> {
    public render() {
        return (
            <div>
                <CreateProjectModal
                    locale={this.props.locale}
                    projectCreate={this.props.projectCreate}
                />

                <Divider clearing={true} hidden={true} />

                {this.renderProjects()}
            </div>
        );
    }

    private renderProjects() {
        if (this.props.projects.length === 0) {
            return (
                <Message
                    info={true}
                    icon="info"
                    header={i18n.t('projects.component.projectList.empty.header')}
                    content={i18n.t('projects.component.projectList.empty.content')}
                />
            );
        }

        return (
            <Card.Group itemsPerRow={4} stackable={true}>
                {this.props.projects.map(project => (
                    <ProjectCard
                        key={project.uuid}
                        project={project}
                        selectProject={this.props.projectSelect}
                        projectRemove={this.props.projectRemove}
                    />
                ))}
            </Card.Group>
        );
    }
}

export default withTranslation()(ProjectList)