import { ConfigurationProducts, Project } from '../../../../types/types';
import * as React from 'react';

import ProductDetailDescriptions from './ProductDetailDescriptions';
import AddConfigurationModal from "../../../projects/components/AddConfigurationModal";
import ProductRendererNew from "./ProductRendererNew";

type Props = {
    products: ConfigurationProducts,
    isSmallScreen: boolean,
    currentProject: Project | null,
    locale: string,
    isUserSelectionValid: boolean
}

class RenderProductRenderer extends React.Component<Props> {

    public render() {

        const { products, locale, currentProject } = this.props;

        return (
            <div className="full-height">
                {!this.props.isSmallScreen &&
                    <ProductDetailDescriptions
                        rooftopArticle={products.rooftop.article}
                        extrasArticles={products.extras.map((extra: { article: any; }) => extra.article).join(' / ')}
                        transitionArticle={products.transition.article}
                        isSmallScreen={this.props.isSmallScreen}
                    />
                }
                {
                // @ts-ignore
                <AddConfigurationModal
                    locale={locale}
                    products={products}
                    currentProject={currentProject}
                    isUserSelectionValid={this.props.isUserSelectionValid}
                    isSmallScreen={this.props.isSmallScreen}
                    style={{position: 'absolute', right: '0px', top: '0px'}}
                />
                }
                {/*<ProductRenderer products={products} />*/}
                <ProductRendererNew products={products}/>
            </div>
        );
    }
}

export default RenderProductRenderer;