import React from "react";
import {Menu} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

interface FooterMenuProps {
  locale: string,
  toggleLocale: Function
}

function FooterMenu(props: FooterMenuProps) {

  const { t } = useTranslation();

  return (
      <Menu text={true} className="footer-menu">
        <Menu.Item
            as="a"
            target="_blank"
            href={t('common.component.footer.imprint.url')}
        >
          {t('common.component.footer.imprint.label')}
        </Menu.Item>
        <Menu.Item
            as="a"
            target="_blank"
            href={t('common.component.footer.privacy.url')}
        >
          {t('common.component.footer.privacy.label')}
        </Menu.Item>
        <Menu.Item
            as="a"
            target="_blank"
            href={t('common.component.footer.terms.url')}
        >
          {t('common.component.footer.terms.label')}
        </Menu.Item>
        <Menu.Item as="a" onClick={() => props.toggleLocale()}>
          {props.locale === 'de' ? 'EN' : 'DE'}
        </Menu.Item>
      </Menu>
  )

}

export default FooterMenu;