import * as React from 'react';
import * as Action from '../../../store/actions';
import { Button, Modal } from 'semantic-ui-react';
import { Project } from '../../../types/types';
import CreateProjectForm from "./CreateProjectForm";
import {useState} from "react";
import {useTranslation} from "react-i18next";

type CreateProjectModalProps = {
    projectCreate: typeof Action.projectCreate,
    locale: string
};


export default function CreateProjectModal(props: CreateProjectModalProps) {

    const [open, setOpen] = useState<boolean>(false);
    const { t } = useTranslation();

    const submit = (values: Project) => {
        props.projectCreate(props.locale, values);
    }

    return (
        <div>
            <Button
                color="blue"
                size="mini"
                icon="plus"
                labelPosition="left"
                content={t('projects.component.createProjectModal.modalOpenButton')}
                onClick={() => setOpen(true)}
            />
            <Modal open={open} closeIcon="close" onClose={() => setOpen(false)}>
                <Modal.Header content={t('projects.component.createProjectModal.header')}/>
                <Modal.Content>
                    <CreateProjectForm
                        onSubmit={submit}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color="red"
                        icon="remove"
                        labelPosition="right"
                        content={t('projects.component.createProjectModal.cancelButton')}
                        onClick={() => setOpen(false)}
                    />
                    <Button
                        type="submit"
                        form="createProject"
                        positive={true}
                        labelPosition="right"
                        icon="checkmark"
                        content={t('projects.component.createProjectModal.createButton')}
                    />
                </Modal.Actions>
            </Modal>
        </div>
    );


}
