import * as React from 'react';
import * as Action from '../../../store/actions';

import {
    Accordion,
    Divider,
    Message,
    Header,
    Container,
    AccordionContent,
    AccordionTitle,
    Icon
} from 'semantic-ui-react';
import {Project, ProjectConfiguration} from "../../../types/types";
import DeleteButton from "../../../components/common/DeleteButton";
import ProductList from "../../../components/common/ProductList";
import I18n from "../../../i18n/i18n";
import {useState} from "react";
import {AccordionTitleProps} from "semantic-ui-react/dist/commonjs/modules/Accordion/AccordionTitle";

type Props = {
    locale: string,
    project: Project,
    projectDeleteConfiguration: typeof Action.projectDeleteConfiguration
};

export default function ProjectConfigurationList(props: Props) {

    const [activeIndexes, setActiveIndexes] = useState([]);

    let configurationList = Object.values(
        props.project.projectConfigurations
            ? props.project.projectConfigurations
            : {}
    );


    if (configurationList.length === 0) {
        return (
            <Message
                info={true}
                icon="info"
                header={I18n.t('projects.component.projectConfigurationList.noConfigurations.header')}
                content={I18n.t('projects.component.projectConfigurationList.noConfigurations.content')}
            />
        );
    }

    function deleteConfiguration(configuration: ProjectConfiguration) {
        props.projectDeleteConfiguration(props.locale, props.project, configuration);
    }

    const handleClick = (e: React.MouseEvent<HTMLDivElement>, data: AccordionTitleProps) => {

        const newIndexes = [ ...activeIndexes ]; // create a new array to avoid mutating the state directly

        // check if an input already exists with the said type & get the index position
        const existingIndex = newIndexes.findIndex((index) => index === data.index);

        if (existingIndex >= 0) {
            newIndexes.splice(existingIndex, 1);
        } else {
            // @ts-ignore
            newIndexes.push(data.index);
        }
        setActiveIndexes(newIndexes);
    }

    const isActive = (uuid: string | undefined) => {
        if (uuid === undefined) {
            return false;
        }
        const existingIndex = activeIndexes.findIndex((index) => index === uuid);
        return existingIndex !== -1;
    }

    function createPanels(dataList: Array<ProjectConfiguration>) {
        return (
            dataList.map((configuration, idx) => {
                return(
                    <div key={configuration.uuid + 'key'}>
                        <AccordionTitle index={configuration.uuid} onClick={handleClick}>
                            <Icon name='dropdown'/>
                            {buildConfigurationTitle(idx + 1, configuration)}
                        </AccordionTitle>
                        {
                        <AccordionContent active={isActive(configuration.uuid)} key={configuration.uuid}>
                            <div key={configuration.uuid}>
                                <ProductList
                                    locale={props.locale}
                                    products={configuration.products}
                                />
                                <Divider/>
                                <DeleteButton
                                    content={I18n.t('projects.component.projectConfigurationList.deleteButton')}
                                    deleteCallback={() => deleteConfiguration(configuration)}
                                />
                            </div>
                        </AccordionContent>
                        }
                    </div>
                )
            })

        )
    }

    function buildConfigurationTitle(position: number, configuration: ProjectConfiguration) {
        let fields = [
            [I18n.t('projects.component.projectConfigurationList.label.lvPos'), configuration.lvPos],
            [I18n.t('projects.component.projectConfigurationList.label.remark'), configuration.remark],
            [I18n.t('projects.component.projectConfigurationList.label.quantity'), configuration.quantity],
            [
                I18n.t('projects.component.projectConfigurationList.label.price'),
                configuration.price === null || configuration.price === `-/-` || configuration.price === `auf Anfrage`
                    ? I18n.t('projects.component.addConfigurationModal.priceOnRequest')
                    : configuration.price
            ]
        ];

        let extras = configuration.products.extras.length > 0
            ? configuration.products.extras.map(item => {
                return `, ${item.article}`
            }).join('')
            : ``;
        let transition = configuration.products.transition.article.length > 0
            ? `, ${configuration.products.transition.article}`
            : ``;

        return (
            <span key={position}>
                {`${position}: ${configuration.article} ${extras} ${transition} |`}
                {fields.map((item, index) => {
                    if (item[1] !== null) {
                        return (
                            <span key={item[0]}><span className="no-line-break">
                                    {` ${item[0]}: ${item[1]} ${index !== 3 ? '| ' : ''}`}
                                </span>{' '}</span>
                        );
                    }
                    return null
                })}
            </span>
        );
    }


    let validConfigurations: Array<ProjectConfiguration> = [];
    let invalidConfigurations: Array<ProjectConfiguration> = [];

    configurationList.forEach((config: ProjectConfiguration) => {
        config.state === 1
            ? validConfigurations.push(config)
            : invalidConfigurations.push(config)
    });

    return (
        <div>
            <Accordion
                fluid={true}
                styled={true}
                exclusive={false}
                panels={createPanels(validConfigurations)}
            />
            {
                invalidConfigurations.length > 0
                    ? <Container>
                        <br/>
                        <Header
                            size="small"
                            color="grey"
                            content={I18n.t('projects.component.projectDetailView.configurations.invalid.header')}
                        />
                        <Accordion
                            fluid={true}
                            styled={true}
                            exclusive={false}
                            children={createPanels(invalidConfigurations)}

                        />
                    </Container>
                    : null
            }
        </div>
    );

}
