import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import translations from "./translations";

const options = {
  order: ['localStorage', 'navigator'],
  lookupQuerystring: 'lng',
  lookupLocalStorage: 'lng',
  caches: ['localStorage'],
}

i18n
    .use(initReactI18next)
    .init({
  detection: options,
  fallbackLng: 'de',
  supportedLngs: ['de', 'en'],
  load: 'languageOnly',
  resources: {
    en: {
      translations: translations.en,
    },
    de: {
      translations: translations.de
    },
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'de'];

export default i18n;