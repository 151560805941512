import * as React from 'react';
import OptionChoiceField from './OptionChoiceField';
import * as Actions from '../../../../store/actions';
import { Menu } from 'semantic-ui-react';
import {FilteredOptions, UserSelection} from "../../../../types/types";
import {withTranslation} from "react-i18next";
import I18n from "../../../../i18n/i18n";

type Props = {
    filteredOptions: FilteredOptions,
    userSelection: UserSelection,
    locale: string,
    setParameter: typeof Actions.setParameter
};

type State = {};

class CreateAssemblyField extends React.PureComponent<Props, State> {
    public render () {
        if (this.props.filteredOptions.assemblys.length > 1) {
            return (
                <Menu.Item>
                    <OptionChoiceField
                        locale={this.props.locale}
                        label={I18n.t('configurator.component.filterForm.label.assembly')}
                        updateValue={value => this.props.setParameter('assembly', value)}
                        options={this.props.filteredOptions.assemblys}
                        value={this.props.userSelection.assembly}
                    />
                </Menu.Item>
            );
        }
        return null;
    }
}

export default withTranslation()(CreateAssemblyField);
