import DataSetHelper from './DataSetHelper';
import {
    ApiConfigurationProducts,
    ApiProject,
    ApiProjectConfiguration,
    ConfigurationProducts,
    Project,
    ProjectConfiguration
} from '../types/types';
import { PRODUCT_STATE } from './constants';

export default class AppObjectConverter {
    private dataSetHelper;

    public constructor(dataSetHelper: DataSetHelper) {
        this.dataSetHelper = dataSetHelper;
    }

    public createProject(project: ApiProject): Project {
        let createdConfigurations = {};

        for (let uuid of Object.keys(project.projectConfigurations)) {
            // @ts-ignore
            createdConfigurations[uuid] = this.createProjectConfiguration(project.projectConfigurations[uuid]);
        }

        return {
            ...project,
            projectConfigurations: createdConfigurations
        };
    }

    public createProjectConfiguration(projectConfiguration: ApiProjectConfiguration): ProjectConfiguration {
        return {
            ...projectConfiguration,
            products: this.createConfigurationProducts(projectConfiguration.products, projectConfiguration.state)
        };
    }

    public createConfigurationProducts(
        productReferences: ApiConfigurationProducts,
        configurationState: number
    ): ConfigurationProducts {
        if (!productReferences.rooftop || !productReferences.transition) {
            throw new Error('Rooftop and Transition must be set');
        }

        return {
            state: configurationState,
            rooftop: {
                ...productReferences.rooftop,
                product: productReferences.rooftop.state === PRODUCT_STATE.AVAILABLE
                    ? this.dataSetHelper.getRooftopById(productReferences.rooftop.id)
                    : null
            },
            transition: {
                ...productReferences.transition,
                // @ts-ignore
                product: productReferences.transition.state === PRODUCT_STATE.AVAILABLE
                    ? this.dataSetHelper.getTransitionById(productReferences.transition.id)
                    : null
            },
            extras: productReferences.extras.map((extra) => {
                return {
                    ...extra,
                    product: extra.state === PRODUCT_STATE.AVAILABLE
                        // @ts-ignore
                        ? this.dataSetHelper.filterById(this.dataSetHelper.dataSet.products.extras, extra.id)
                        : null
                }
            }),
        };
    }
}
