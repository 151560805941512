import React from 'react';
import {Container, Header, Segment} from "semantic-ui-react";
import LoginForm from "../forms/LoginForm";
import {AuthState} from "../types/types";
import {useNavigate} from "react-router-dom";

type LoginStateProps = {
  authState: AuthState,
};

function LoginPage(props: LoginStateProps) {

  const navigate = useNavigate();

  if (props.authState.isAuthenticated) {
    navigate('/')
  }

  return (
      <Container>
        <Header as="h1">
          Login
        </Header>

        <Segment className="login-segment">
          <LoginForm/>
        </Segment>
      </Container>
  );
}





export default (LoginPage);
