import { Physics, PhysicsResult } from '../../../types/types';
import AbstractCalculator from './AbstractCalculator';
import {Parser} from "mathjs";

export default class RectangularSystemCalculator extends AbstractCalculator {
    public getErrors(): string[] {
        return [];
    }

    protected getPhysics(): Physics | null {
        if (this.physics.length === 0) {
            return null;
        }

        let userA = this.userSelection.sizeA;
        let userB = this.userSelection.sizeB;

        if (userA && userB) {
            let physicsA = this.getNearestPhysics(userA || 0, 'A');
            let physicsB = this.getNearestPhysics(userB || 0, 'B');

            if (physicsA && physicsB) {
                if ((physicsA.A || 0) <= (physicsB.B || 0)) {
                    return physicsA;
                }

                return physicsB;
            }
        }

        return null;
    }

    protected createResult(parser: Parser): PhysicsResult {

        parser.set('A', this.userSelection.sizeA || 0);
        parser.set('B', this.userSelection.sizeB || 0);
        parser.set('L', this.userSelection.louverSelection || 0);

        return {
            v: parser.evaluate('calcV(A, B, V, L)'),
            pt: parser.evaluate('calcPt(A, B, V, L)'),
            lw: parser.evaluate('calcLw(A, B, V, L)')
        };
    }

    private getNearestPhysics(value: number, field: string): Physics | null {
        let physics: Physics | null = null,
            diff = Number.MAX_SAFE_INTEGER;

        for (let current of this.physics) {
            // @ts-ignore
            let newDiff = Math.abs(value - (current[field] || 0));
            if (newDiff < diff) {
                physics = current;
                diff = newDiff;
            }
        }

        return physics;
    }
}
