import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { Product } from "../../../../types/types";
import Choice from "../../../../components/common/Choice";

;


type Props = {
    locale: string,
    products: Product[],
    value: number | string | null,
    updateValue: (value: string) => void
};

export default class ProductChoiceField extends React.Component<Props> {
    render() {
        return (
            <Form.Field>
                <Choice choices={this.buildChoices()} value={this.props.value} updateValue={this.props.updateValue} />
            </Form.Field>
        );
    }

    private buildChoices() {
        return this.props.products.map(item => ({
            key: item.id,
            value: item.id,
            text: item.translations[this.props.locale] ? item.translations[this.props.locale].title : item.id,
            image: item.image
        }));
    }
}
