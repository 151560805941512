import * as React from 'react';
import { ConfigurationProducts } from '../../types/types'
import { Card } from 'semantic-ui-react';
import {PRODUCT_STATE} from "../../util/constants";
import ValidProductItem from "./ValidProductItem";
import InvalidProductItem from "./InvalidProductItem";


type Props = {
    locale: string,
    products: ConfigurationProducts
};

export default class ProductList extends React.Component<Props> {
    public render() {
        let {locale, products} = this.props;

        if (products.rooftop && products.transition) {
            return (
                <Card.Group itemsPerRow="3" stackable={true} doubling={true}>
                    {
                        products.rooftop.state === PRODUCT_STATE.AVAILABLE && products.rooftop.product
                        ? <ValidProductItem
                            key={products.rooftop.id}
                            article={products.rooftop.article}
                            locale={locale}
                            product={products.rooftop.product}
                        />
                        : <InvalidProductItem
                            key={products.rooftop.id}
                            article={products.rooftop.article}
                            state={products.rooftop.state}
                        />
                    }

                    {products.extras.map((extra) => {
                        if (extra) {
                            return (
                                extra.state === PRODUCT_STATE.AVAILABLE && extra.product
                                ? <ValidProductItem
                                    key={extra.id}
                                    article={extra.article}
                                    locale={locale}
                                    product={extra.product}
                                />
                                : <InvalidProductItem
                                    key={extra.id}
                                    article={extra.article}
                                    state={extra.state}
                                />
                            )
                        }
                        return null;
                    })}

                    {
                        products.transition.state === PRODUCT_STATE.AVAILABLE && products.transition.product
                        ? <ValidProductItem
                            key={products.transition.id}
                            article={products.transition.article}
                            locale={locale}
                            product={products.transition.product}
                        />
                        : <InvalidProductItem
                                key={products.transition.id}
                                article={products.transition.article}
                                state={products.transition.state}
                        />
                    }

                </Card.Group>
            );
        }
        return null;
    }
}
