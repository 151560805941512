import * as React from 'react';

import { Message } from 'semantic-ui-react';

class InvalidConfiguration extends React.PureComponent {
    public render() {
        return (
            <Message negative={true}>
                <Message.Header>
                    {'configurator.configuratorPage.invalidConfiguration.header'}
                </Message.Header>
                <p>{'configurator.configuratorPage.invalidConfiguration.content'}</p>
            </Message>
        );
    }
}

export default InvalidConfiguration;