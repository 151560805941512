import React from 'react';
import {bindActionCreators} from "@reduxjs/toolkit";
import {Dispatch} from "redux";
import {SelectProjectAction} from "../store/actions";
import * as Action from '../store/actions';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Button, Form, Icon, Message} from "semantic-ui-react";
import FormInput from "../components/form/FormInput";
import {object, string, TypeOf} from "zod";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";



const requestPasswordResetFormSchema = object({
  email: string().min(1, { message: 'user.formValidatorMessages.required'}).email({message: 'user.formValidatorMessages.emailNotValid'}),
});

export type RequestPasswordResetInput = TypeOf<typeof requestPasswordResetFormSchema>;

type DispatchProps = {
  requestPasswordReset: typeof Action.requestPasswordReset
};

function RequestPasswordResetForm(props: DispatchProps) {

  const { t } = useTranslation();

  const methods = useForm<RequestPasswordResetInput>({
    resolver: zodResolver(requestPasswordResetFormSchema),
  });

  const {
    handleSubmit,
    formState: { isSubmitSuccessful, isValid, isSubmitted },
  } = methods;

  const onSubmitHandler: SubmitHandler<RequestPasswordResetInput> = async (values) => {
    props.requestPasswordReset(values);
  };

  const rtLbl = 'user.component.registration.label.';

  return (
      <FormProvider {...methods}>

        {isSubmitted ?
            <Message positive={true}>
              <Message.Header>
                {t('user.component.resetPassword.requestReset.success.headline')}
              </Message.Header>
              <p>{t('user.component.resetPassword.requestReset.success.copytext')}</p>
            </Message>
            :
            <div className="login-segment">

              <Message>
                <Message.Header>{t('user.component.resetPassword.requestReset.info.header')}</Message.Header>
                <Message.List as="ol" className={'ordered-message-list'}>
                  <Message.Item>{t('user.component.resetPassword.requestReset.info.1')}</Message.Item>
                  <Message.Item>{t('user.component.resetPassword.requestReset.info.2')}</Message.Item>
                  <Message.Item>{t('user.component.resetPassword.requestReset.info.3')}</Message.Item>
                </Message.List>
              </Message>

              <Form onSubmit={handleSubmit(onSubmitHandler)}>
                <FormInput
                    name={'email'}
                    type={'email'}
                    label={t(rtLbl + 'email')}
                    autoComplete={'username'}
                />

                <div>
                  <Button type="submit" disabled={!isValid} color={'blue'}>
                    <Icon name="send"/> {t('user.component.resetPassword.requestReset.submitButton')}
                  </Button>
                </div>
              </Form>

            </div>
        }

      </FormProvider>
  );
}


function mapDispatchToProps(dispatch: Dispatch<SelectProjectAction>): DispatchProps {
  return bindActionCreators(
      {
        requestPasswordReset: Action.requestPasswordReset
      },
      dispatch
  );
}

export default connect(null, mapDispatchToProps)(RequestPasswordResetForm);
