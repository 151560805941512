
import { AuthState } from '../../types/types';
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {LoginAction, LogoutAction} from "../actions";

let initialState: AuthState = {
    token: null,
    isAuthenticated: false,
    username: null
}

const authSlide = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, action: PayloadAction<LoginAction>) {
            return {
                ...state,
                token: action.payload.token,
                isAuthenticated: true,
                username: action.payload.username
            };
        },
        logout(state, action: PayloadAction<LogoutAction>) {
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                username: null
            };
        },
    },
})

export const { login, logout } = authSlide.actions
export default authSlide.reducer