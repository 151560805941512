import * as React from 'react';
import { Icon, Label, Menu, Tab, Divider } from 'semantic-ui-react';
import {ConfigurationProducts, Project} from "../../../types/types";
import RenderProductRenderer from "./productView/RenderProductRenderer";
import InvalidConfiguration from "./productView/InvalidConfiguration";
import AddConfigurationModal from "../../projects/components/AddConfigurationModal";
import ProductList from "../../../components/common/ProductList";
import ProjectController from "../../projects/ProjectController";
import I18n from "../../../i18n/i18n";
import {withTranslation} from "react-i18next";


type Props = {
    locale: string,
    currentProject: Project | null,
    products: ConfigurationProducts | null,
    isUserSelectionValid: boolean,
    isSmallScreen: boolean
};

class ProductView extends React.Component<Props> {
    public render() {

        const panes = [
            {
                menuItem: {
                    key: '3d',
                    icon: 'users',
                    content: this.props.isSmallScreen
                        ? I18n.t('mobileText.configurator.component.productView.tab.productRenderer')
                        : I18n.t('configurator.component.productView.tab.productRenderer')
                },
                render: () => (
                    <Tab.Pane className="render-product-renderer">
                        {this.props.products !== null
                            ? <RenderProductRenderer
                                isSmallScreen={this.props.isSmallScreen}
                                locale={this.props.locale}
                                currentProject={this.props.currentProject}
                                products={this.props.products}
                                isUserSelectionValid={this.props.isUserSelectionValid}
                            />
                            : <InvalidConfiguration />
                        }
                    </Tab.Pane>
                )
            },
            {
                menuItem: {
                    key: 'products',
                    icon: 'grid layout',
                    content: this.props.isSmallScreen
                        ? I18n.t('mobileText.configurator.component.productView.tab.productList')
                        : I18n.t('configurator.component.productView.tab.productList')
                },
                render: () => (
                    <Tab.Pane>
                        {this.props.products !== null
                            ? <div className="full-height">

                                {   // @ts-ignore
                                    <AddConfigurationModal
                                    locale={this.props.locale}
                                    products={this.props.products}
                                    currentProject={this.props.currentProject}
                                    isUserSelectionValid={this.props.isUserSelectionValid}
                                    style={{position: 'relative', right: '0px', display: 'block'}}
                                    isSmallScreen={this.props.isSmallScreen}
                                />}
                                <Divider clearing={true} hidden={true} />
                                <ProductList
                                    locale={this.props.locale}
                                    products={this.props.products}
                                />
                            </div>
                            : <InvalidConfiguration/>
                        }
                    </Tab.Pane>
                )
            },
            {
                menuItem: this.renderProjectMenuItem(),
                render: () => (
                    <Tab.Pane>
                        <ProjectController
                            // @ts-ignore
                            isSmallScreen={this.props.isSmallScreen}
                        />
                    </Tab.Pane>
                )
            }
        ];

        return (
            <Tab panes={panes} className="product-view"/>
        );
    }

    private renderProjectMenuItem() {
        if (this.props.currentProject) {
            return (
                <Menu.Item key="project" position="right">
                    <Icon name="folder outline"/>
                    {this.props.isSmallScreen
                        ? I18n.t('mobileText.configurator.component.productView.tab.project')
                        : this.props.currentProject.title}
                    {!this.props.isSmallScreen
                        && <Label>{
                            this.props.currentProject.projectConfigurations
                                ? Object.values(this.props.currentProject.projectConfigurations).length
                                : '-'
                        }</Label>
                    }
                </Menu.Item>
            );
        }

        return (
            <Menu.Item
                key="project"
                position="right"
                icon="folder outline"
                id="nikosRed"
                content={
                    <em>
                        {this.props.isSmallScreen
                            ? I18n.t('mobileText.configurator.component.productView.tab.project')
                            : I18n.t('configurator.component.productView.tab.noProject')
                        }
                    </em>
                }
            />
        );
    }
}

export default withTranslation()(ProductView)