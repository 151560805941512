import * as React from 'react';
import { ConfigurationProducts, FilteredOptions, UserSelection } from "../../../../types/types";
import * as Actions from '../../../../store/actions';
import { Menu } from 'semantic-ui-react';
import { NONINSULATED, SYSTEM_RECTANGULAR, SYSTEM_ROUND } from '../../../../util/constants';
import DiameterField from './DiameterField';
import RectangularSizesFields from './RectangularSizesFields';
import ExternalDiameterField from './ExternalDiameterField';
import {withTranslation} from "react-i18next";

type Props = {
    filteredOptions: FilteredOptions,
    userSelection: UserSelection,
    setParameter: typeof Actions.setParameter,
    products: ConfigurationProducts | null,
};
type State = {};

class CreateSystemDependantItems extends React.Component<Props, State> {
    public render() {
        let rooftop = this.props.products && this.props.products.rooftop.product
            ? this.props.products.rooftop.product
            : null;

        if (this.props.userSelection.system === SYSTEM_ROUND) {
            return (
                <div>
                    <Menu.Item>
                        <DiameterField
                            diameterValues={this.props.filteredOptions.dn}
                            currentDiameter={this.props.userSelection.diameter}
                            minDiameter={rooftop ? rooftop.minDN : null}
                            maxDiameter={rooftop ? rooftop.maxDN : null}
                            updateValue={value => this.props.setParameter('diameter', value)}
                        />
                    </Menu.Item>
                    {this.props.userSelection.insulation !== NONINSULATED &&
                        <Menu.Item>
                            <ExternalDiameterField
                                extDiameterValues={this.props.filteredOptions.d2}
                                currentExtDiameter={this.props.userSelection.d2}
                                updateValue={value => this.props.setParameter('d2', value)}
                            />
                        </Menu.Item>
                    }
                </div>
            );
        }

        if (this.props.userSelection.system === SYSTEM_RECTANGULAR) {
            return (
                <Menu.Item>
                    <RectangularSizesFields
                        sizeA={this.props.userSelection.sizeA}
                        sizeB={this.props.userSelection.sizeB}
                        min={rooftop ? rooftop.minAB : null}
                        max={rooftop ? rooftop.maxAB : null}
                        setParameter={this.props.setParameter}
                    />
                </Menu.Item>
            );
        }

        return null;
    }
}

export default withTranslation()(CreateSystemDependantItems);