import * as React from 'react';
import { Form } from 'semantic-ui-react';
import Choice from "../../../../components/common/Choice";
import { Option } from "../../../../types/types";

type Props = {
    locale: string,
    label: string,
    options: Option[],
    value: number | string | null,
    updateValue: (value: string) => void
};

export default class OptionChoiceField extends React.Component<Props> {
    public render() {
        return (
            <Form.Field>
                <label>{this.props.label}</label>
                <Choice
                    choices={this.buildChoices()}
                    value={this.props.value}
                    updateValue={this.props.updateValue}
                />
            </Form.Field>
        );
    }

    private buildChoices() {
        return this.props.options
            .map(item => ({
                key: item.id,
                value: item.id === null ? '' : item.id,
                text: item.translations[this.props.locale].title
            }));
    }
}
