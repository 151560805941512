import React, {SyntheticEvent} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {Dropdown, DropdownItemProps, DropdownProps, Label, FormField, SemanticWIDTHS} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

type FormDropdownProps = {
    label?: string;
    name: string;
    options: DropdownItemProps[],
    readonly?: boolean;
    mandatory?: boolean;
    width?: SemanticWIDTHS;
};

const FormDropdown: React.FC<FormDropdownProps> = ({
      label,
      name,
      options,
      readonly = false,
      mandatory = false,
      width = undefined,
  }) => {

  const { t } = useTranslation();

  const {
      register,
      formState: { errors },
      setValue,
  } = useFormContext();

  const inputValue = useWatch({
      name: name,
  });

  let inputClass = '';
  if (inputValue) {
      inputClass += ' has-value';
  }

  let wrapperClass = '';
  if (mandatory) {
    wrapperClass += ' mandatory';
  }

  let hasError = errors[name];
  let errorClass = '';
  if (hasError) {
    errorClass = ' error';
  }

  return (
      <FormField className={wrapperClass + errorClass} width={width}>

        {label &&
          <>
            <label htmlFor={name} className='input-label'>
              {label}
            </label>
          </>
        }

        <Dropdown
          className="dropdown"
          selection={true}
          options={options}
          value={inputValue}
          {...register(name)}
          onChange={
            (event: SyntheticEvent<HTMLElement, Event>,
             data: DropdownProps) => {
              return setValue(name, data.value);
          }}
        />

        {errors[name] && (
          <Label basic={true} color="red" pointing="above">
            {// @ts-ignore
              t(errors[name]?.message)
            }
          </Label>
        )}

      </FormField>
    );
};

export default FormDropdown;